import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import mA from '../Assets/Hadoop.png'
import dev from '../Assets/Hadoop1.jpg'
import inquimg from '../Assets/undraw-contact.svg'
import ContactForm from '../Components/ContactForm'

function Hadoop() {
  return (
    <>
        <Navbar/>
        <div className="center">
    <br/>
    <br/>
        <img className="devops" src={mA} />
    <br/>
    <br/>

      </div>

      <div className="devops-detail">
        <h1>
Hadoop: Revolutionizing Big Data Processing</h1>
        <div className="lines"></div>
        <br />
        <p>
        Hadoop, an open-source framework, has emerged as a game-changer in the realm of big data processing, offering scalable and distributed storage and processing capabilities. Initially developed by the Apache Software Foundation, Hadoop addresses the challenges posed by the explosion of data in the digital age, providing a robust solution for storage, processing, and analysis.
        </p>
        <p>
        Hadoop's design prioritizes scalability, allowing organizations to seamlessly scale their infrastructure by adding more machines to the Hadoop cluster. Additionally, Hadoop incorporates fault tolerance mechanisms, ensuring continued operations even in the presence of hardware failures. Data is replicated across multiple nodes in the cluster, minimizing the risk of data loss.
        </p>
        <br />
        {/* <p>
        Implementing a comprehensive DevOps pipeline for a website involves several key components. Starting with version control, establish a repository using a system like Git and define branching strategies to facilitate collaboration. Enforce branch protection and code review policies to maintain code quality. The continuous integration (CI) phase comes next, where automated builds are configured to trigger upon code commits. Utilize CI tools such as Jenkins or Azure Pipelines to compile code, run tests, and generate artifacts.<br/> Manage these artifacts in a repository like Azure Artifacts or JFrog Artifactory, ensuring proper versioning for traceability. Automated testing is integral to the pipeline, encompassing unit, integration, and end-to-end tests. Unit tests validate individual components, while integration tests verify interactions between them. End-to-end tests ensure the holistic functionality of the website. Throughout this process, consider the automation of testing within the CI/CD pipeline to maintain efficiency. This structured approach to DevOps facilitates continuous integration, enabling faster and more reliable development cycles for 
        </p> */}
      </div>
      <div className="devops-detail-section">
        <div className="devops-detail-section-left">
          <h1>Ecosystem and Tools:</h1>
          <div className="lines"></div>

          <br />
          {/* <h3>  Fast on All Planes:</h3> */}
          <p>
        
          Hadoop boasts a rich ecosystem of tools and frameworks that complement its core components. Apache Hive and Apache Pig provide high-level interfaces for querying and analyzing data, while Apache Spark offers in-memory data processing, significantly boosting processing speeds. Hadoop's flexibility extends to its compatibility with various programming languages, enabling developers to work with Java, Python, and more.
          </p>
          <br/>

          {/* <h3>Enabling Data-Driven Cultures:</h3> */}
          <p>
          
          Hadoop finds applications across diverse industries, from finance and healthcare to retail and telecommunications. Its ability to handle massive datasets and perform complex analytics facilitates tasks such as fraud detection, customer segmentation, and predictive modeling.
          </p>
          <br />
          {/* <h3>Challenges and Ongoing Advancements:

</h3> */}
          <br />

          {/* <p>
       
          While the implementation of Power BI offers significant advantages, challenges may include ensuring data quality, managing security, and addressing the learning curve for new users. The future trajectory of Power BI involves embracing advancements in artificial intelligence and machine learning integration, promising even more sophisticated analytical capabilities.
          </p> */}
          <br/>
          {/* <h3>Integrated Development</h3> */}
          <br/>
          {/* <p>
Azure has an added benefit of having an ingrained development environment i.e. visual Studio, which is a game changer for most companies. This is the main reason for reducing learning associated with a new platform. Any mismatching skill can be altogether avoided and developer teams can be instantaneously deployed on jobs. During Azure Cloud training, students can greatly benefit from its tight integrations and its willingness to adapt to the developers’ needs.</p> */}
        </div>
        <div className="devops-detail-section-right">
          <img src={dev} />
        </div>
      </div>
      {/* <div class="content">
    
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          

          <div class="row justify-content-center">
        
            <div class="col-md-6">
              
              <h3 class="heading mb-4">Let's talk about everything!</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p>

              <p><img src={inquimg} alt="Image" class="img-fluid"/></p>


            </div>
            <div class="col-md-6">
            <br/>
          <br/>
              <form class="mb-5" method="post" id="contactForm" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="name" id="name" placeholder="Your name"/>
                  </div>

                </div>
                <br/>
                <div class="row d-flex flex-row">
                  <div class="col-md-6 form-group">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Email"/>
                  </div>
                  <div class="tech-down col-md-6">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
                </div>
                <br/>

         
                <br/>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <br/>

                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"/>
                  <span class="submitting"></span>
                  </div>
                </div>
                <br/>

              </form>

             
             
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> */}
  <ContactForm/>
        <Footer/>
    </>
  )
}

export default Hadoop