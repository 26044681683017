import React, { useState } from 'react';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer';

function WorkWithUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNo: '',
    skills: '',
    location: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    fetch('https://kstraining-server.vercel.app/api/trainer/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        // Handle success, e.g., show a success message to the user
        alert('Form submitted successfully!');
        // Reset the form
        document.getElementById('myForm').reset();
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle error, e.g., show an error message to the user
      });
  };
  return (
  <>
  <Navbar/>
    <div className='workwithus'>
    <h1>Contact Us</h1>


    </div>
    <div className='berainer'>
        <h2>Feel Free to Drop Us a Message</h2>
        <br/>
        <p>Have a question or need assistance with our online courses? Feel free to reach out to our dedicated support team. Your educational journey matters to us, and we're here to ensure you have a seamless and enriching experience. Contact us for any inquiries or guidance – we're just a message away!</p>
    </div>
    <br/>
    <br/>
    <br/>
    <div className='inpt-section'>
      <div className='inpt'>
        <label>Your Name</label>
        <input name='name' placeholder='Your Name' onChange={handleChange} />
        <br />
        <br />
        <label>Your Email</label>
        <input name='email' placeholder='Your Email' onChange={handleChange} />
        <br />
        <br />
        <label>Phone Number</label>
        <input name='mobileNo' placeholder='Phone Number' type='number' onChange={handleChange} />
        <br />
        <br />
        <label>Your Skills</label>
        <input name='skills' placeholder='Your Skills' onChange={handleChange} />
        <br />
        <br />
        <label>Your Location</label>
        <input name='location' placeholder='Your Location' onChange={handleChange} />
        <br />
        <br />
        <label>Your Message</label>
        <textarea name='message' rows='4' cols='50' onChange={handleChange}></textarea>
        <br />
        <br />
        <button onClick={handleSubmit}>Send</button>
      </div>
    </div>

    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
   <Footer/>
  </>
  )
}

export default WorkWithUs