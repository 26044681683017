import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import pic from '../Assets/th.jpg'

function Testi() {
    const testimonials = [
        {
          id: 1,
          author: 'John Doe',
          content: 'Codility enables us to shorten the feedback loap between the stack holders. We solved the pain points by digitizing one places of the puzzle-the technical assessment. today, we bring the best tailent into the organization by being agile and flexible.',
        },
        {
          id: 2,
          author: 'Jane Doe',
          content: 'Codility enables us to shorten the feedback loap between the stack holders. We solved the pain points by digitizing one places of the puzzle-the technical assessment. today, we bring the best tailent into the organization by being agile and flexible.',
        },
        // Add more testimonials as needed
      ];
    
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,  // Enable auto-sliding
        autoplaySpeed: 5000,  // Set the duration for each slide (in milliseconds)
      };
  return (
    <div className="testimonial-container">

      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-item">



          <div className='testimonial-itemss'>
          <div className='testimonial-itemss-img'>
          <img src={pic}/>

</div>
<br/>
          <div className='testimonial-itemss-name'>
          <h2 className="author">{testimonial.author}</h2>
          <p>{testimonial.content}</p>

</div>
           
          </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Testi