import React from 'react'
import Navbar from '../Components/Navbar'
import azure from '../Assets/GraphicsViewer.png'
import devops from '../Assets/640px-Devops-toolchain.svg.png'
import dev from '../Assets/7bsd9pw99e76jhhx5ep5.webp'
import Footer from '../Components/Footer'
import inquimg from '../Assets/undraw-contact.svg'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ContactForm from '../Components/ContactForm'

function Tech() {
  return (
   <>
    <Navbar/>
    <br/>
    <br/>
    <div className='center'>
    <img className='devops' src={devops}/>

    </div>
    <div className='devops-detail'>
        <h1>
            DevOps
        </h1>
 
     
        <p>
            
DevOps is a set of practices that combines software development (Dev) and IT operations (Ops) to shorten the systems development life cycle and provide continuous delivery with high software quality. It aims to help an organization produce software and services more efficiently and with greater reliability.
        </p>
        <br/>
        <p>
        Implementing a comprehensive DevOps pipeline for a website involves several key components. Starting with version control, establish a repository using a system like Git and define branching strategies to facilitate collaboration. Enforce branch protection and code review policies to maintain code quality. The continuous integration (CI) phase comes next, where automated builds are configured to trigger upon code commits. Utilize CI tools such as Jenkins or Azure Pipelines to compile code, run tests, and generate artifacts.<br/> Manage these artifacts in a repository like Azure Artifacts or JFrog Artifactory, ensuring proper versioning for traceability. Automated testing is integral to the pipeline, encompassing unit, integration, and end-to-end tests. Unit tests validate individual components, while integration tests verify interactions between them. End-to-end tests ensure the holistic functionality of the website. Throughout this process, consider the automation of testing within the CI/CD pipeline to maintain efficiency. This structured approach to DevOps facilitates continuous integration, enabling faster and more reliable development cycles for 
        </p>
       

    </div>

    <div className='devops-detail-section'>
    <div className='devops-detail-section-left'>
    <h1>
    AWS DevOps
        </h1>
        <div className="lines"></div>

        <br/>
        <p>
            
DevOps is a set of practices that combines software development (Dev) and IT operations (Ops) to shorten the systems development life cycle and provide continuous delivery with high software quality. It aims to help an organization produce software and services more efficiently and with greater reliability.
        </p>
        <p>
            
            In an AWS DevOps ecosystem, managing and securing containers is simplified with AWS Elastic Container Service (ECS) or Kubernetes-based Amazon Elastic Kubernetes Service (EKS). These services enable the orchestration, scaling, and deployment of containerized applications, supporting a microservices architecture. AWS Lambda functions can be integrated to execute serverless computing, allowing developers to focus on writing code without the need to manage infrastructure. For continuous monitoring and feedback loops, AWS X-Ray can be employed to trace requests across microservices, providing insights into performance bottlenecks. AWS Identity and Access Management (IAM) ensures secure access to AWS resources by managing user permissions and roles, while AWS Key Management Service (KMS) allows for encryption of sensitive data. The AWS Marketplace further extends the DevOps toolkit, offering a range of third-party tools and solutions that can be seamlessly integrated into the AWS environment, enhancing functionality and flexibility within the DevOps pipeline.
                    </p>
    </div>
    <div className='devops-detail-section-right'>
<img src={dev}/>
</div>

    </div>
    
    <div className='devops-detail-section'>
    <div className='devops-detail-section-right'>
<img src={azure}/>
</div>
    <div className='devops-detail-section-left'>
    <h1>
    Azure DevOps
        </h1>
        <div className="lines"></div>

        <br/>
        <p>
            

        Azure DevOps, Microsoft's integrated suite for DevOps practices, encompasses a range of tools and services seamlessly integrated to streamline the entire development lifecycle. Azure Repos serves as a version control system, supporting Git and TFVC for collaborative code development, while Azure Pipelines empowers teams with robust continuous integration and delivery capabilities. This CI/CD platform allows the creation of automated pipelines for building, testing, and deploying applications across diverse platforms. Azure Artifacts complements this by providing a package management service, facilitating the storage and sharing of packages. The comprehensive Azure DevOps suite further extends to include features for collaborative code reviews, issue tracking, and comprehensive project management, fostering efficient and collaborative development practices for organizations leveraging the Microsoft Azure cloud ecosystem.
        </p>
        <p>
            
         
Azure DevOps extends beyond version control and continuous integration to offer a holistic DevOps experience. Azure Boards provides agile project management tools for planning, tracking work, and managing backlogs using customizable boards. For comprehensive testing, Azure Test Plans offers a suite of testing tools, enabling teams to plan, track, and manage testing efforts. Azure DevOps also supports Infrastructure as Code (IaC) through Azure Resource Manager (ARM) templates, facilitating the provisioning and management of Azure resources. Additionally, Azure Monitor and Azure Application Insights allow for robust monitoring, logging, and insights into application performance, helping teams identify and resolve issues efficiently. Azure DevOps serves as a unified platform, fostering collaboration across development, operations, and other stakeholders, thus enabling organizations to deliver high-quality software with efficiency and speed.
                    </p>
    </div>
   

    </div>
{/* 
    <div className='inq-from'>
    <h1>For Inquiry</h1>
    <p>Elevate Your Learning Experience in Our Technology Classes!</p>
    <br/>
    <div className='inq-from-ips'>
        <input placeholder='First Name'/>
        <input placeholder='Last Name'/>
    </div>
    <div className='inq-from-ips'>
        <input placeholder='Email'/>
        <input placeholder='Mobile No.'/>
    </div>
    <div className='inq-from-ips'>
        <input placeholder='Address'/>
        <input placeholder='Address'/>
    </div>
 
    <div class="tech-down">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
    
<br/>
<button className='submit-form'>Submit</button>

    </div> */}
    {/* <div class="content">
    
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          

          <div class="row justify-content-center">
        
            <div class="col-md-6">
              
              <h3 class="heading mb-4">Let's talk about everything!</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p>

              <p><img src={inquimg} alt="Image" class="img-fluid"/></p>


            </div>
            <div class="col-md-6">
            <br/>
          <br/>
              <form class="mb-5" method="post" id="contactForm" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="name" id="name" placeholder="Your name"/>
                  </div>

                </div>
                <br/>
                <div class="row d-flex flex-row">
                  <div class="col-md-6 form-group">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Email"/>
                  </div>
                  <div class="tech-down col-md-6">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
                </div>
                <br/>

         
                <br/>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <br/>

                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"/>
                  <span class="submitting"></span>
                  </div>
                </div>
                <br/>

              </form>

             
             
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> */}

  {/* <div className='info-form'>
  <div className='info-form-opac'>
  <div className='info-form-left'>
<div className='info-div'>
<div className='info1'>
<div className='icon-bg'>
<i class="bi bi-geo-alt-fill"></i>
</div>
<p>loramloram loramloramloramloramloramloramloram<br/>loramloramloramloramloramloram</p>

</div>
<div className='info1'>
<div className='icon-bg'>
<i class="bi bi-geo-alt-fill"></i>
</div>
<p>loramloram loramloramloramloramloramloramloram<br/>loramloramloramloramloramloram</p>

</div>
<div className='info1'>
<div className='icon-bg'>
<i class="bi bi-geo-alt-fill"></i>
</div>
<p>loramloram loramloramloramloramloramloramloram<br/>loramloramloramloramloramloram</p>

</div>
</div>
  </div>
  <div className='info-form-right'>
  <div className='info-form-div'>
  <Box
      component="form"
      
      noValidate
      autoComplete="off"
    >
    
      <TextField style={{width:'100%'}} id="standard-basic" label="Your Name" variant="standard" />
    </Box>

    <br/>
    <Box
      component="form"
      
      noValidate
      autoComplete="off"
      style={{display:'flex' , justifyContent:'space-between'}}
    >
      <TextField style={{width:'45%'}} id="" label="Email" variant="standard" />
    
      <select style={{width:'45%'}} class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>
    </Box>
    <br/>
    <Box
      component="form"
      
      noValidate
      autoComplete="off"
    >
    
      <TextField style={{width:'100%'}} id="standard-basic" label="Write Your Message" variant="standard" />
    </Box>
    <br/>
    <br/>
    <button className='sendmsg'>Send Message</button>



</div>

</div>

</div>

  </div> */}
<ContactForm/>
 
 
    <Footer/>
   </>
  )
}

export default Tech