import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

import mA from '../Assets/Salesforce Marketing Cloud Integration and Support Services.jpeg'
import dev from '../Assets/Salesforce Community Implementations.jpeg'
import inquimg from '../Assets/undraw-contact.svg'
import ContactForm from '../Components/ContactForm'

function Salesforce() {
  return (
    <>
        <Navbar/>
        <div className="center">
    <br/>
    <br/>
        <img className="sales" src={mA} />
    <br/>
    <br/>

      </div>

      <div className="devops-detail">
        <h1>Salesforce</h1>
        <div className="lines"></div>
      
        <p>
       
        Salesforce is a prominent cloud-based Customer Relationship Management (CRM) platform that revolutionizes how businesses manage customer interactions and streamline operations. Operating on a cloud model, Salesforce offers a suite of products, including Sales Cloud for sales automation, Service Cloud for customer support, and Marketing Cloud for marketing automation. Its customization capabilities allow businesses to tailor the platform to their specific needs, utilizing features like Apex programming language for development. AppExchange, an extensive marketplace, provides a plethora of third-party apps and integrations. Salesforce's commitment to security, compliance, and its active community support further solidify its position as a leading CRM solution, widely adopted across diverse industries for fostering robust and enduring customer relationships.
        </p>
   
        <br />
        {/* <p>
        Implementing a comprehensive DevOps pipeline for a website involves several key components. Starting with version control, establish a repository using a system like Git and define branching strategies to facilitate collaboration. Enforce branch protection and code review policies to maintain code quality. The continuous integration (CI) phase comes next, where automated builds are configured to trigger upon code commits. Utilize CI tools such as Jenkins or Azure Pipelines to compile code, run tests, and generate artifacts.<br/> Manage these artifacts in a repository like Azure Artifacts or JFrog Artifactory, ensuring proper versioning for traceability. Automated testing is integral to the pipeline, encompassing unit, integration, and end-to-end tests. Unit tests validate individual components, while integration tests verify interactions between them. End-to-end tests ensure the holistic functionality of the website. Throughout this process, consider the automation of testing within the CI/CD pipeline to maintain efficiency. This structured approach to DevOps facilitates continuous integration, enabling faster and more reliable development cycles for 
        </p> */}
      </div>
      <div className="devops-detail-section">
        <div className="devops-detail-section-left">
          <h1>Key Features and Strengths</h1>
          <div className="lines"></div>

          <br />
          {/* <h3>  Fast on All Planes:</h3> */}
          <p>
        
          Salesforce's key features encompass a comprehensive suite of tools designed to elevate customer relationship management. Sales Cloud streamlines sales processes and enhances productivity, while Service Cloud provides robust customer support and service automation. The Marketing Cloud enables targeted and personalized marketing campaigns, and Commerce Cloud supports e-commerce functionalities. Community Cloud fosters collaboration with customers and partners through online communities, and the platform's customization capabilities allow businesses to tailor their CRM instance to specific needs. AppExchange, Salesforce's marketplace, offers an extensive array of third-party apps and integrations. The Trailhead Learning Platform engages users in interactive and gamified learning experiences, enhancing their Salesforce skills. With analytics and reporting tools, mobile accessibility, workflow automation, seamless integrations, and a commitment to security and compliance, Salesforce stands as a versatile and powerful solution for businesses seeking to optimize and strengthen their customer relationships.
          </p>
          <br/>

          {/* <h3>Enabling Data-Driven Cultures:</h3> */}
          <p>
          
          Salesforce's robust analytics and reporting capabilities empower businesses to gain valuable insights into their data, track performance metrics, and make informed strategic decisions. The platform's mobile access ensures flexibility, allowing users to manage CRM data from anywhere. Workflow automation features reduce manual effort by automating repetitive tasks, enhancing efficiency and ensuring consistency in business processes. Salesforce's strong integration capabilities enable seamless connections with third-party applications, databases, and external systems through APIs, creating a unified and interconnected business environment. The platform's unwavering commitment to security, incorporating data encryption and compliance with industry regulations, ensures the safeguarding of sensitive customer information. Overall, Salesforce stands out as a dynamic and scalable CRM solution that adapts to the diverse needs of businesses across various industries, fostering growth and success in customer relationship management.
          </p>
          <br />
          {/* <h3>Challenges and Ongoing Advancements:

</h3> */}
          <br />

          {/* <p>
       
          While the implementation of Power BI offers significant advantages, challenges may include ensuring data quality, managing security, and addressing the learning curve for new users. The future trajectory of Power BI involves embracing advancements in artificial intelligence and machine learning integration, promising even more sophisticated analytical capabilities.
          </p> */}
          <br/>
          {/* <h3>Integrated Development</h3> */}
          <br/>
          {/* <p>
Azure has an added benefit of having an ingrained development environment i.e. visual Studio, which is a game changer for most companies. This is the main reason for reducing learning associated with a new platform. Any mismatching skill can be altogether avoided and developer teams can be instantaneously deployed on jobs. During Azure Cloud training, students can greatly benefit from its tight integrations and its willingness to adapt to the developers’ needs.</p> */}
        </div>
        <div className="devops-detail-section-right">
          <img src={dev} />
        </div>
      </div>
      {/* <div class="content">
    
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          

          <div class="row justify-content-center">
        
            <div class="col-md-6">
              
              <h3 class="heading mb-4">Let's talk about everything!</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p>

              <p><img src={inquimg} alt="Image" class="img-fluid"/></p>


            </div>
            <div class="col-md-6">
            <br/>
          <br/>
              <form class="mb-5" method="post" id="contactForm" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="name" id="name" placeholder="Your name"/>
                  </div>

                </div>
                <br/>
                <div class="row d-flex flex-row">
                  <div class="col-md-6 form-group">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Email"/>
                  </div>
                  <div class="tech-down col-md-6">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
                </div>
                <br/>

         
                <br/>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <br/>

                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"/>
                  <span class="submitting"></span>
                  </div>
                </div>
                <br/>

              </form>

             
             
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> */}
  <ContactForm/>
        <Footer/>
    </>
  )
}

export default Salesforce