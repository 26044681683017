import React from 'react'
import dots from '../Assets/Ellipse 3991.svg'
import slid from '../Assets/image 47.svg'
import img1 from '../Assets/image 60.svg'
import img2 from '../Assets/Rectangle 22.svg'
import Navbar from '../Components/Navbar'

function HowWorks() {
  return (
   <>
   <Navbar/>
   <div className='intro'>
   <h1>
   INTRODUCTION VIDEO
   </h1>

   <div className='works'>
   <div className='worksa'>
<h1>How Gethire.ai Works</h1>
<br/>
<button>Watch Over Video</button>
</div>

   </div>

   </div>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <br/>
   <div className='cost'> 
   <div className='PRICING'>PRICING</div>
   <br/>


   <h1>Costing & ticket price</h1>
   <br/>
   <br/>
   <div className='scrollable-container'>

  
<div className='pc'>
<div className='prising-cards'>
<div className='prising-card'>
<h1>VIP</h1>

<h2>$1299</h2>
<br/>

<div className='line'></div>
<br/>
<ul>
  <li><img src={dots}/>Access to Professional Development </li>
  <li><img src={dots}/>Curated Summit Buddy Intro (matching is<br/> based on your professional development goals) </li>
  <li><img src={dots}/>Access to All In-Person Keynotes</li>
  <li><img src={dots}/>Access to All In-Person Breakout Sessions</li>
  <li><img src={dots}/>Access to In-Person Summit Networking<br/> & Career Fair</li>
  <li><img src={dots}/>Event App </li>
  <li><img src={dots}/>Access to All Virtual Content</li>
  <li><img src={dots}/>Access to Video Recordings After the Summit</li>

</ul>

<div className='REGISTER'>
<button>REGISTER</button>

</div>

<br/>


</div>
<div className='prising-card'>
<h1>VIP</h1>

<h2>$1299</h2>
<br/>

<div className='line'></div>
<br/>
<ul>
  <li><img src={dots}/>Access to Professional Development </li>
  <li><img src={dots}/>Curated Summit Buddy Intro (matching is<br/> based on your professional development goals) </li>
  <li><img src={dots}/>Access to All In-Person Keynotes</li>
  <li><img src={dots}/>Access to All In-Person Breakout Sessions</li>
  <li><img src={dots}/>Access to In-Person Summit Networking<br/> & Career Fair</li>
  <li><img src={dots}/>Event App </li>
  <li><img src={dots}/>Access to All Virtual Content</li>
  <li><img src={dots}/>Access to Video Recordings After the Summit</li>

</ul>

<div className='REGISTER'>
<button>REGISTER</button>

</div>

<br/>


</div>
<div className='prising-card'>
<h1>VIP</h1>

<h2>$1299</h2>
<br/>

<div className='line'></div>
<br/>
<ul>
  <li><img src={dots}/>Access to Professional Development </li>
  <li><img src={dots}/>Curated Summit Buddy Intro (matching is<br/> based on your professional development goals) </li>
  <li><img src={dots}/>Access to All In-Person Keynotes</li>
  <li><img src={dots}/>Access to All In-Person Breakout Sessions</li>
  <li><img src={dots}/>Access to In-Person Summit Networking<br/> & Career Fair</li>
  <li><img src={dots}/>Event App </li>
  <li><img src={dots}/>Access to All Virtual Content</li>
  <li><img src={dots}/>Access to Video Recordings After the Summit</li>

</ul>

<div className='REGISTER'>
<button>REGISTER</button>

</div>

<br/>


</div>

</div>
</div>
 </div>

   </div>
   <br/>
<div className='whatover'>
<div className='whatover-left'>
<h2>WHAT OVER VIEWERS THINK ABOUT US</h2>
<br/>
<br/>
<h3>At Skillgenic, we value the thoughts and opinions of our viewers. Here's a glimpse into what they have to say about their experience with us:</h3>
<br/>
<br/>

<p>“Skillgenic's platform made hiring a breeze. The one-click automation and AI-driven matching saved us time and resources”.</p>


</div>
<div className='whatover-right'>
<img src={slid}/>


</div>

</div>
<div className='dec'>
<h1>2024 PRESALE ENDS FRIDAY,<br/> DECEMBER 15TH AT 11:59PM CT!</h1>
<br/>
<p>3-DAY TICKETS START AT $25 DOWN WITH A LAYAWAY PLAN</p>
</div>
<div className='speaker-section'>
<div className='speaker'>
<h1>Speaker Spotlight Series</h1>
<br/>

<p>Gain valuable insights from industry leaders and visionaries who will share their expertise at Skillgenic Placement Fest.</p>
<br/>
<br/>
<br/>
<button>REGISTER</button>

</div>
<div className='speaker'>
<h1>Speaker Spotlight Series</h1>
<br/>

<p>Gain valuable insights from industry leaders and visionaries who will share their expertise at Skillgenic Placement Fest.</p>
<br/>
<br/>
<br/>
<button>REGISTER</button>

</div>
<div className='speaker'>
<h1>Speaker Spotlight Series</h1>
<br/>

<p>Gain valuable insights from industry leaders and visionaries who will share their expertise at Skillgenic Placement Fest.</p>
<br/>
<br/>
<br/>
<button>REGISTER</button>

</div>
</div>
<div class="gallery">
  <img src={img1} alt="Image 1"/>

 
</div>
<div className='blogs'>
<div className='PRICING'>Blogs</div>
   <br/>
   <h1>Blogs And Articles</h1>
   <br/>
   <br/>
   <div className='blog-cards-container'>
   <div className='pc'>
   <div className='blog-cards'>
   <div className='blog-card'>
   <img src={img2}/>
   <div className='blog-desc'>
   <br/>

    <div className='Insight'>Insight</div>
    <br/>

    <p>12 Aug 2023</p>
    <br/>
    <h4>Many Univerz university graduates<br/> immediately work</h4>
    <br/>
    <h3>Lorem ipsum dolor sit amet, consectetur<br/> adipiscing elit.  </h3>
   </div>



</div>
   <div className='blog-card'>
   <img src={img2}/>
   <div className='blog-desc'>
   <br/>

    <div className='Insight'>Insight</div>
    <br/>

    <p>12 Aug 2023</p>
    <br/>
    <h4>Many Univerz university graduates<br/> immediately work</h4>
    <br/>
    <h3>Lorem ipsum dolor sit amet, consectetur<br/> adipiscing elit.  </h3>
   </div>



</div>
   <div className='blog-card'>
   <img src={img2}/>
   <div className='blog-desc'>
   <br/>

    <div className='Insight'>Insight</div>
    <br/>

    <p>12 Aug 2023</p>
    <br/>
    <h4>Many Univerz university graduates<br/> immediately work</h4>
    <br/>
    <h3>Lorem ipsum dolor sit amet, consectetur<br/> adipiscing elit.  </h3>
   </div>



</div>
</div>
   </div>
   </div>
   <br/>
   <br/>



</div>
   <footer>
    <div className='footer-top'>
    <div className='f1'>
    <div className="logo">
            <p>logo</p>

          
          </div>
          <br/>
    <h3>Mission</h3>
    <br/>
    <p>We are a social enterprise on a mission to accelerate the regeneration of nature. We enable companies to gift trees that are brought back in the heart of Africa. Easily, at scale, and with an epic customizable gifting experience.</p>
    <br/>
    <h4>Sign up for Our Newsletter</h4>
    <br/>
   <div className='footerinput'> <input placeholder='Enter your email' /><button>Sign Up</button></div>

    </div>
    <div className='f2'>
      <ul>Pages
        <li>Plateform</li>
        <li>Solution</li>
        <li>Resources</li>
        <li>Our Customers</li>
        <li>Event</li>

      </ul>
    </div>
    <div className='f3'>
      <h5>Registration for sponsorship & collaboration</h5>
    <br/>  <button>REGISTER</button>
    <br/> 
    <br/> 
      <h3>Contact Info</h3>
      <br/> 
      <h2>+4863154859</h2>
    </div>



    </div>
    <div className='footer-bottom'>
    <ul>
      <li>Terms and condition</li>
      <li>Privacy policy</li>
      <li>All rights reserved gethire..</li>

    </ul>


    </div>

    </footer>

   </>
  )
}

export default HowWorks