import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import mA from '../Assets/azure-training-in-chennai.png'
import dev from '../Assets/azure_logo_794_new-570x570.png'
import inquimg from '../Assets/undraw-contact.svg'
import ContactForm from '../Components/ContactForm'

function MicrosoftAzure() {
  return (
    <>
        <Navbar/>
        <br/>
        <br/>

        <div className="center">
        <img className="devops" src={mA} />
      </div>

      <div className="devops-detail">
        <h1>Microsoft Azure</h1>
        <div className="lines"></div>
        <br />
        <p>
        Microsoft Azure is a relatively new cloud computing service, launched by Microsoft, but it has made quite an impact in the world in the past few years. When learning cloud computing online, Microsoft Azure is a word that you will hear quite often. Before enrolling in any cloud computing courses, get to know more about Microsoft Azure.
        </p>
        <p>
        It is used for deploying, building and for the management of applications, through a cohesive Microsoft network of data centers, which provide SaaS (Software-as-a-service), IaaS (Infrastructure-as-a-Service) and PaaS (Product-as-a-Service). It supports a number of programming languages, frameworks and tools, which are specific not only to Microsoft but also to several other third-party systems and software.
        </p>
        <br />
        {/* <p>
        Implementing a comprehensive DevOps pipeline for a website involves several key components. Starting with version control, establish a repository using a system like Git and define branching strategies to facilitate collaboration. Enforce branch protection and code review policies to maintain code quality. The continuous integration (CI) phase comes next, where automated builds are configured to trigger upon code commits. Utilize CI tools such as Jenkins or Azure Pipelines to compile code, run tests, and generate artifacts.<br/> Manage these artifacts in a repository like Azure Artifacts or JFrog Artifactory, ensuring proper versioning for traceability. Automated testing is integral to the pipeline, encompassing unit, integration, and end-to-end tests. Unit tests validate individual components, while integration tests verify interactions between them. End-to-end tests ensure the holistic functionality of the website. Throughout this process, consider the automation of testing within the CI/CD pipeline to maintain efficiency. This structured approach to DevOps facilitates continuous integration, enabling faster and more reliable development cycles for 
        </p> */}
      </div>
      <div className="devops-detail-section">
        <div className="devops-detail-section-left">
          <h1>Microsoft Azure</h1>
          <div className="lines"></div>

          <br />
          <h3>  Fast on All Planes:</h3>
          <p>
        
Speed is factor that can make or break operations for a company and is a definitive marker of excellence and a competitive advantage for many. MS Azure is fast with its amazing speed prevalent in areas of deployment, scalability and operations. With its on premise solutions and speed in all three areas, there are not many cloud providers that can keep up with them.
          </p>
          <br/>

          <h3>Fast and Easy Deployment</h3>
          <p>
          
Furthermore, you can enhance your business’s agility and speed of operations with MS Azure. With faster deployment services and on premise solutions, companies can greatly benefit from Azure. They can develop their apps faster and can get instant feedback, thus becoming more iterative. This can help companies examine technological outcomes that will accelerate and suit their business objectives.
          </p>
          <br />
          <h3>   Business Agility</h3>
          <br />

          <p>
       
Shorter product cycles means less financial risk and better scalability. During MS azure training you will learn all about faster product cycles resulting in measures scalability without a complex infrastructure and an expensive outlay. It works seamlessly with all industries with the ability to tailor its services depending on the drive innovation and technological needs.
          </p>
          <br/>
          <h3>Integrated Development</h3>
          <br/>
          <p>
Azure has an added benefit of having an ingrained development environment i.e. visual Studio, which is a game changer for most companies. This is the main reason for reducing learning associated with a new platform. Any mismatching skill can be altogether avoided and developer teams can be instantaneously deployed on jobs. During Azure Cloud training, students can greatly benefit from its tight integrations and its willingness to adapt to the developers’ needs.</p>
        </div>
        <div className="devops-detail-section-right">
          <img src={dev} />
        </div>
      </div>
      {/* <div class="content">
    
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          

          <div class="row justify-content-center">
        
            <div class="col-md-6">
              
              <h3 class="heading mb-4">Let's talk about everything!</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p>

              <p><img src={inquimg} alt="Image" class="img-fluid"/></p>


            </div>
            <div class="col-md-6">
            <br/>
          <br/>
              <form class="mb-5" method="post" id="contactForm" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="name" id="name" placeholder="Your name"/>
                  </div>

                </div>
                <br/>
                <div class="row d-flex flex-row">
                  <div class="col-md-6 form-group">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Email"/>
                  </div>
                  <div class="tech-down col-md-6">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
                </div>
                <br/>

         
                <br/>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <br/>

                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"/>
                  <span class="submitting"></span>
                  </div>
                </div>
                <br/>

              </form>

             
             
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> */}
  <ContactForm/>
        
        <Footer/>
    </>
  )
}

export default MicrosoftAzure