import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import mA from '../Assets/MuleSoft_logo_299C.png'
import dev from '../Assets/muleinline.webp'
import inquimg from '../Assets/undraw-contact.svg'
import ContactForm from '../Components/ContactForm'

function Mulesoft() {
  return (
    <>
        <Navbar/>

        <div className="center">
        <br/>
        <br/>
        <br/>
        <img className="devops" src={mA} />
        <br/>
        <br/>

      </div>

      <div className="devops-detail">
        <h1>MuleSoft</h1>
        <div className="lines"></div>
        <br />
        <p>
        MuleSoft, now a part of Salesforce, stands at the forefront of integration and connectivity solutions, providing organizations with a robust platform to connect applications, data, and devices seamlessly. MuleSoft's Anypoint Platform offers a comprehensive suite of tools designed to streamline the complex task of integrating disparate systems and services.


        </p>
        <p>
        {/* It is used for deploying, building and for the management of applications, through a cohesive Microsoft network of data centers, which provide SaaS (Software-as-a-service), IaaS (Infrastructure-as-a-Service) and PaaS (Product-as-a-Service). It supports a number of programming languages, frameworks and tools, which are specific not only to Microsoft but also to several other third-party systems and software. */}
        </p>
        <br />
        {/* <p>
        Implementing a comprehensive DevOps pipeline for a website involves several key components. Starting with version control, establish a repository using a system like Git and define branching strategies to facilitate collaboration. Enforce branch protection and code review policies to maintain code quality. The continuous integration (CI) phase comes next, where automated builds are configured to trigger upon code commits. Utilize CI tools such as Jenkins or Azure Pipelines to compile code, run tests, and generate artifacts.<br/> Manage these artifacts in a repository like Azure Artifacts or JFrog Artifactory, ensuring proper versioning for traceability. Automated testing is integral to the pipeline, encompassing unit, integration, and end-to-end tests. Unit tests validate individual components, while integration tests verify interactions between them. End-to-end tests ensure the holistic functionality of the website. Throughout this process, consider the automation of testing within the CI/CD pipeline to maintain efficiency. This structured approach to DevOps facilitates continuous integration, enabling faster and more reliable development cycles for 
        </p> */}
      </div>
      <div className="devops-detail-section">
        <div className="devops-detail-section-left">
          <h1>MuleSoft: Unlocking Seamless Integration and Connectivity</h1>
          <div className="lines"></div>

          <br />
          <h3>          Anypoint Studio:</h3>
          <p>
        
At the heart of MuleSoft's offering is Anypoint Studio, an intuitive development environment that enables developers to design, build, and test integration flows. With a visual, drag-and-drop interface, Anypoint Studio simplifies the creation of API-led connectivity solutions.


          </p>
          <br/>

          <h3>          API-Led Connectivity:
</h3>
          <p>
          
MuleSoft adopts an API-led approach, allowing organizations to expose, manage, and consume APIs in a modular and reusable manner. This approach enhances agility and accelerates development by breaking down integration tasks into manageable components.
          </p>
          <br />

        </div>
        <div className="devops-detail-section-right">
          <img src={dev} />
        </div>
      </div>
      <div className="devops-detail-section" style={{display:'flex' , flexDirection:'column', alignItems:'start'}}>

        
          <h3>          Integration Connectors:
</h3>
          <br />

          <p>
       
MuleSoft provides a rich library of pre-built connectors, facilitating integration with a myriad of applications, databases, and systems. These connectors cover a wide range of technologies, ensuring adaptability and interoperability.
          </p>
          <br/>
          <h3>          Anypoint Exchange:
</h3>
          <br/>
          <p>
Anypoint Exchange serves as a central hub for reusable assets such as connectors, templates, and API specifications. This fosters collaboration among development teams and accelerates the implementation of integration patterns.
</p>
        </div>
       
  
      {/* <div class="content">
    
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          

          <div class="row justify-content-center">
        
            <div class="col-md-6">
              
              <h3 class="heading mb-4">Let's talk about everything!</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p>

              <p><img src={inquimg} alt="Image" class="img-fluid"/></p>


            </div>
            <div class="col-md-6">
            <br/>
          <br/>
              <form class="mb-5" method="post" id="contactForm" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="name" id="name" placeholder="Your name"/>
                  </div>

                </div>
                <br/>
                <div class="row d-flex flex-row">
                  <div class="col-md-6 form-group">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Email"/>
                  </div>
                  <div class="tech-down col-md-6">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
                </div>
                <br/>

         
                <br/>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <br/>

                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"/>
                  <span class="submitting"></span>
                  </div>
                </div>
                <br/>

              </form>

             
             
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> */}
  <ContactForm/>
        <Footer/>
    </>
  )
}

export default Mulesoft