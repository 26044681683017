import React, { useState } from 'react';
import Navbar from "../Components/Navbar";
import star from "../Assets/Star 57.svg";
import cor from "../Assets/pngwing.com.png";
import mac from "../Assets/How-to-Deliver-a-World-Class-Course-Choosing-the-Best-Delivery-Method-1.png";
import mac1 from "../Assets/Macbook Air (2022)1.svg";
import phone from "../Assets/talk-to-your-colleagues.png";
import candi from "../Assets/1pngwing.com.png";
import img2 from "../Assets/Notch & Camera.svg";
import img3 from "../Assets/image 26.svg";
import img4 from '../Assets/Ellipse 3990.svg'
import img5 from '../Assets/courses-removebg-preview.png'
import arrow from '../Assets/solar_arrow-left-broken.svg'
import start from '../Assets/Star.svg'
import google from '../Assets/image 32.svg'
import playstore from '../Assets/image 33.svg'
import Footer from '../Components/Footer';
import Testi from '../Components/Testi';
import { Link } from 'react-router-dom';


function Home() {
  const [activeTab, setActiveTab] = useState(0);

  const changeTab = (index) => {
    setActiveTab(index);
  };
  
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const accordionData = [
    { title: 'What are the engagement and connection services offfered bt tring ?', content: 'Content for Section 1' },
    { title: 'What is the process to book a Tring Video?', content: 'Content for Section 2' },
    { title: 'How Much time does it take for my request to get delivered ?', content: 'Content for Section 3' },
    { title: 'are the prices visible on the website also applicable for brand/corporate orders?', content: 'Content for Section 3' },
    { title: 'What if Celebrity does not complete the video ?', content: 'Content for Section 3' },
    { title: 'Why did my orders get declined ?', content: 'Content for Section 3' },

  ];
  return (
    <>
      <Navbar />
      <div className="banner">
        {/* <div className="IMS">
        <div className="IMS-head-container">
      <ul className="IMS-head">
        <li>Platform</li>
        <li>Screen</li>
        <li>Interview</li>
        <li>Schedule</li>
        <li>Structure</li>
      </ul>
    </div>
          <br />
          <br />

          <p>Empower Your Future: Stay Ahead with State-of-the-Art Online Courses</p>
        

          <h1>The Future of Interviewing</h1>
          <br />
          <br />
          <h3>
          Stay ahead of the curve with our cutting-edge online courses. Master tomorrow's tech at your own pace, guided by industry experts. Elevate your skills and seize future opportunities with us.
          </h3>
          <br />
       
          <div className="box-btns">
            <button className="book">Book a Demo <img src={arrow}/></button>
            <button className="w2">Watch 2 minute video</button>
          </div>
          <br />
          <br />

          <div className="stars">
            <div className="rate">
              <div>
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
              </div>
              <p>G2 Reviews</p>
            </div>
            <div className="rate">
              <div>
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
              </div>
              <p>Get App</p>
            </div>
            <div className="rate">
              <div>
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
              </div>
              <p>Capterra</p>
            </div>
            <div className="rate">
              <div>
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
                <img src={star} />
              </div>

              <p>Software Advice</p>
            </div>
          </div>
          <br />
        </div> */}
        <div className='banner-section'>

        <p>Empower Your Future: Stay Ahead with State-of-the-Art Online Courses</p>
        <h3>
          Stay ahead of the curve with our cutting-edge online courses. Master tomorrow's tech at your own pace, guided by industry experts. Elevate your skills and seize future opportunities with us.
          </h3>
          <br/>
          <button>Read More</button>
        </div>
      </div>

      <div className="corporate">
        <h3>Your Path to a Successful Tech Career!</h3>
        <br />
        <h2>
          <span>5,000,000 careers advanced

</span>
          <span> 1,500 live classes every month</span> are
          <br />85% report career benefits including promotion or a new job
        </h2>
        <br/>
        <br/>
        <img className='core' src={cor} />
      </div>

      <div className="aboutvision">
        <div className="aboutvision-left">
          <h3>About Vision</h3>
          <br />
          <h2>
          We transform lives by empowering people via digital skills.
          </h2>
          <br />{" "}
          <p>
          At KS Training , we are on a mission to empower individuals in the dynamic world of technology. Our commitment is to provide a cutting-edge, inclusive learning environment that goes beyond traditional education. We strive to equip our learners with practical skills through industry-expert crafted courses, fostering global connections, and facilitating career transformation. Our mission is to not just meet but exceed expectations, ensuring that every individual, regardless of their background, has the opportunity to thrive and lead in the rapidly evolving tech landscape. Join us in shaping the future of technology education and advancing your career with confidence and competence..
          </p>
          <br />
          <button>Learn More <img src={arrow}/></button>
        </div>
        <div className="aboutvision-right">
          <img src={mac} />
        </div>
      </div>
      <div className="aboutproducts">
        <br />
        <br />
        <p>Services We Provide</p>

        <h3>Empower Your Learning Journey with an Aspirational Peer <br/>Community at KS Training</h3>
        <br />
        <br />

    
        <div className='ServicesProvides'>
        <div className='ServicesProvide'>
        
     <div className='center'>
     <div className='ServicesProvide-icon'>
        <i class="bi bi-calendar2-event-fill"></i>
        </div>
        <br />

        <h3>MONTHLY PLAN</h3>

     </div>
     <p>Support for 5 days a week (Monday to Friday) daily 1 hour to 2 hours of support would be provided based on requirement. You can connect using TeamViewer, Skype, go-to meeting etc. Payment would be on monthly basis.</p>

</div>
    <div className='ServicesProvide'>
        
        <div className='center'>
        <div className='ServicesProvide-icon'>
        <i class="bi bi-list-task"></i>
           </div>
           <br />
   
           <h3>TASK BASED</h3>
   
        </div>
        <p>Support for your specific task (one or two days assignment). You can connect using TeamViewer, Skype, go-to meeting etc. Charges will be based on complexity of work and number of hours.</p>
   
   </div>

        </div>
        <br />


        <br/>

        <div className="lap">
          <div className="lap-one">
            <div className="lap-one-box">
              <img src={mac1} />
              <br />
              <br />
              <br />
              <h3>Interactive Excellence</h3>

              <p>Discover the Engaging Benefits of Our Online Course Offerings</p>
            </div>
            <br />

            <div className="lap-one-box">
              <img src={mac1} />
              <br />
              <br />
              <h3>Efficiency Redefined</h3>
              

              <p>The Streamlined Services That Make Our Online Courses Stand Out</p>
            </div>
          </div>
          <div className="lap-two">
            <img src={phone} />
            <br />
            <br />
            <br />
            <h3>Personalized Learning, Exceptional Services<br/> The Signature of Our Online Courses</h3>

            {/* <p>Asynchronous and live video interview with Automated Scheduling</p> */}
          </div>
          <div className="lap-one">
            <div className="lap-one-box">
              <img src={mac1} />
              <br />
              <br />
              <br />
              <h3>Exceeding Expectations</h3>

              <p>Services That Set Our Online Courses Apart</p>
            </div>
            <br />

            <div className="lap-one-box">
              <img src={mac1} />
              <br />
              <br />
              <h3>Achieve More, Anywhere</h3>
          

              <p>Unveiling the Benefits of Our Online Course Services</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="hiring">
        <h3>Tech Up: Online Courses for Your Next Level!</h3>

        <div className="candidate-profile">
          <div className="candidate-profile-left">
            <p>Tech Mastery Awaits: Online Courses for You!</p>
            
            <h2>Find courses that are best for your career</h2>
            

            <h3>
            Boost your skills with our top-notch online courses and certifications covering management, technology, programming, and data science. Whether you want to become a better leader, learn the latest tech, or analyze data like a pro, we've got you covered. Our courses are easy to follow, taught by experts, and full of practical exercises. Start learning today and take your career to new heights!
            </h3>
            <br />
            <br />

           

            <button>Signup & Practice</button>
          </div>
          <div className="candidate-profile-right">
            <img className='candi' src={candi} />
          </div>
        </div>
      </div>

      {/* <div className="practice">
        <br />
        <p>Coding Practice :</p>
        <br />

        <h2>Join the movement. Screen on skills.</h2>
        <br />
        <br />
        <br />

        <img className='dash1' src={img2} />
      </div>

      <div className="binary">
        <div className="binary-left">
          <h1>Leave the binary tree behind.</h1>
          <br />
          <p>
            Build coding questions using our library of dev-friendly content
            that challenges them to solve the problems they’d actually tackle on
            the job.
          </p>
          <br />
          <br />
          <button>Start Free Trial</button>
        </div>
        <div className="binary-right">
          <img src={img3} />
        </div>

      </div>
      <br />
      <br />
      <br />

      <div className="binary">
        <div className="binary-left">
          <h1>Leave the binary tree behind.</h1>
          <br />
          <p>
            Build coding questions using our library of dev-friendly content
            that challenges them to solve the problems they’d actually tackle on
            the job.
          </p>
          <br />
          <br />
          <button>Start Free Trial</button>
        </div>
        <div className="binary-right">
          <img src={img3} />
        </div>
      </div>
      <br />
      <br />
      <br />

      <div className="binary">
        <div className="binary-left">
          <h1>Leave the binary tree behind.</h1>
          <br />
          <p>
            Build coding questions using our library of dev-friendly content
            that challenges them to solve the problems they’d actually tackle on
            the job.
          </p>
          <br />
          <br />
          <button>Start Free Trial</button>
        </div>
        <div className="binary-right">
          <img src={img3} />
        </div>
      </div>
      <br />
      <br />
      <br /> */}
<br/>
<br/>
      <div className="tec-Interview">
        <p>Mastering Tomorrow's Tech with <br/>Cutting-Edge Online Courses</p>
        {/* <h1>Interview like it’s 2023</h1> */}
        <h3>
          Dive into the future of tech with our online courses! Learn the latest skills in AI, cybersecurity, web development, and more. Join a community of learners, get expert guidance, and study at your own pace. Your journey to mastering tomorrow's tech starts here!
        </h3>
        <button>Learn More</button>
      </div>

      <div className="TESTMONIALS">
        <p>TESTMONIALS</p>
        <h1>Our Customers</h1>
        <h2>
        Our students consistently express appreciation for the <br/>engaging and interactive nature of our online courses, praising the seamless blend of<br/> technology and learning that makes education accessible and enjoyable.
        </h2>
      </div>

      {/* <div className='test-tab'>
      <ul>
      <li className={activeTab === 0 ? 'active' : ''} onClick={() => changeTab(0)}>Swift</li>
        <li className={activeTab === 1 ? 'active' : ''} onClick={() => changeTab(1)}>Unity</li>
        <li className={activeTab === 2 ? 'active' : ''} onClick={() => changeTab(2)}>Live Rump</li>
        <li className={activeTab === 3 ? 'active' : ''} onClick={() => changeTab(3)}>Asseco</li>

      </ul>
      <br/>
      <br/>
      <div className='bar' style={{ width: `${activeTab * 122}px` }}></div>

      </div>
      <br></br>
      <br></br>
      <br></br> */}


      {/* <div className="TESTMONIALS-section">

      <div className="review">
      <div className="review-head">
      <img src={img4}/>
      <div>
        <p>Mick joe..</p>
        
        <h2>Head of Sales And Marketing</h2>
      </div>
      

      </div>
      <br/>
      <br/>
     
<h3 className='comments'>“Codility enables us to shorten the feedback loap between the stack holders. We solved the pain points by digitizing one places of the puzzle-the technical assessment. today, we bring the best tailent into the organization by being agile and flexible,” </h3>
<br/>
<br/><button>Read Case Study</button>
      </div>
  
        
      </div> */}
      {/* <Test/> */}
      <Testi/>
      <br></br>
      <br></br>
      <br></br>
      <div className="platform">
  <div className="platform-left">
        <h2>Revolutionize Your Learning Experience: <br/>
        Unleash the Power of KS Training in Our Online Courses, Where Knowledge Meets Skill in a Seamless Learning Experience.</h2>
        <br/>
      
      <h1>Drop Us a Line – We're Listening</h1>
      <br/>
  

      {/* <p>The Best Hiring decisions are made when candidates ‘show’ not tell’.</p> */}
    
    
    <Link to='/workwithus'>

      <button>Request Demo</button>
    </Link>

  
  </div>
  <div className="platform-right">
  <img src={img5}/>

  </div>

      </div>

      {/* <div className="accordion accordion2">
<br/><br/><br/><br/>
<div className='center'>
<br/><br/>
<div className='faq'>FAQ</div>
<br/>
<h1 style={{textAlign:'center'}}>Frequently Asked Questions</h1>
<br/><br/>

</div>

<br/>
      {accordionData.map((section, index) => (
        <div className="accordion-section" key={index}>
        
          <div className="accordion-header" onClick={() => toggleAccordion(index)}>
            {section.title}
          </div>
          {activeIndex === index && (
            <div className="accordion-content">{section.content}</div>
          )}
        </div>
      ))}
      <br/>
      <br/>
      <br/>
    </div> */}
      {/* <footer>
    <div className='footer-top'>
    <div className='f1'>
    <div className="logo">
            <p>logo</p>

          
          </div>
          <br/>
    <h3>Mission</h3>
    <br/>
    <p>We are a social enterprise on a mission to accelerate the regeneration of nature. We enable companies to gift trees that are brought back in the heart of Africa. Easily, at scale, and with an epic customizable gifting experience.</p>
    <br/>
    <h4>Sign up for Our Newsletter</h4>
    <br/>
   <div className='footerinput'> <input placeholder='Enter your email' /><button>Sign Up</button></div>

    </div>
    <div className='f2'>
      <ul>Pages
        <li>Plateform</li>
        <li>Solution</li>
        <li>Resources</li>
        <li>Our Customers</li>
        <li>Event</li>

      </ul>
    </div>
    <div className='f3'>
      <h5>Registration for sponsorship & collaboration</h5>
    <br/>  <button>REGISTER</button>
    <br/> 
    <br/> 
      <h3>Contact Info</h3>
      <br/> 
      <h2>+4863154859</h2>
    </div>



    </div>
    <div className='footer-bottom'>
    <ul>
      <li>Terms and condition</li>
      <li>Privacy policy</li>
      <li>All rights reserved gethire..</li>

    </ul>


    </div>

    </footer> */}
    <Footer/>


    </>
  );
}

export default Home;
