import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

function ContactForm() {
  return (
   <>
      <div className='info-form'>
  <div className='info-form-opac'>
  <div className='info-form-left'>
<div className='info-div'>
<div className='info1'>
<div className='icon-bg'>
<i class="bi bi-geo-alt-fill"></i>
</div>
<p>loramloram loramloramloramloramloramloramloram<br/>loramloramloramloramloramloram</p>

</div>
<div className='info1'>
<div className='icon-bg'>
<i class="bi bi-geo-alt-fill"></i>
</div>
<p>loramloram loramloramloramloramloramloramloram<br/>loramloramloramloramloramloram</p>

</div>
<div className='info1'>
<div className='icon-bg'>
<i class="bi bi-geo-alt-fill"></i>
</div>
<p>loramloram loramloramloramloramloramloramloram<br/>loramloramloramloramloramloram</p>

</div>
</div>
  </div>
  <div className='info-form-right'>
  <div className='info-form-div'>
  <Box
      component="form"
      
      noValidate
      autoComplete="off"
    >
    
      <TextField style={{width:'100%'}} id="standard-basic" label="Your Name" variant="standard" />
    </Box>

    <br/>
    <Box
      component="form"
      
      noValidate
      autoComplete="off"
      style={{display:'flex' , justifyContent:'space-between'}}
    >
      <TextField style={{width:'45%'}} id="" label="Email" variant="standard" />
    
      <select style={{width:'45%'}} class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>
    </Box>
    <br/>
    <Box
      component="form"
      
      noValidate
      autoComplete="off"
    >
    
      <TextField style={{width:'100%'}} id="standard-basic" label="Write Your Message" variant="standard" />
    </Box>
    <br/>
    <br/>
    <button className='sendmsg'>Send Message</button>



</div>

</div>

</div>

  </div>
   </>
  )
}

export default ContactForm