import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import mA from '../Assets/growing-need-social-share.jpg'
import dev from '../Assets/Cybersecurity_Hero.svg'
import inquimg from '../Assets/undraw-contact.svg'
import ContactForm from '../Components/ContactForm'

function Cybersecurity() {
  return (
   <>
    <Navbar/>
    {/* <div className="center">
        <img className="devops" src={mA} />
      </div> */}

      <div className="devops-detail">
        <h1>Cybersecurity</h1>
        <div className="lines"></div>
        <br />
        <p>
        In an era dominated by digital connectivity, cybersecurity stands as the bulwark against the rising tide of cyber threats. It encompasses a range of practices, technologies, and measures designed to protect computer systems, networks, and sensitive data from unauthorized access, attacks, and damage. Cybersecurity is not merely a technical endeavor; it is a dynamic and ongoing effort to stay ahead of evolving cyber threats and vulnerabilities.
        </p>
        <p>
        In our interconnected world, cybersecurity emerges as the sentinel defending against an ever-expanding array of cyber threats. Its core mission is to fortify computer systems, networks, and sensitive data against unauthorized access, attacks, and potential harm. Beyond a technical necessity, cybersecurity is an ongoing and dynamic effort, crucial for maintaining the integrity and resilience of digital ecosystems.
        </p>
        <br />
        {/* <p>
        Implementing a comprehensive DevOps pipeline for a website involves several key components. Starting with version control, establish a repository using a system like Git and define branching strategies to facilitate collaboration. Enforce branch protection and code review policies to maintain code quality. The continuous integration (CI) phase comes next, where automated builds are configured to trigger upon code commits. Utilize CI tools such as Jenkins or Azure Pipelines to compile code, run tests, and generate artifacts.<br/> Manage these artifacts in a repository like Azure Artifacts or JFrog Artifactory, ensuring proper versioning for traceability. Automated testing is integral to the pipeline, encompassing unit, integration, and end-to-end tests. Unit tests validate individual components, while integration tests verify interactions between them. End-to-end tests ensure the holistic functionality of the website. Throughout this process, consider the automation of testing within the CI/CD pipeline to maintain efficiency. This structured approach to DevOps facilitates continuous integration, enabling faster and more reliable development cycles for 
        </p> */}
      </div>
      <div className="devops-detail-section">
        <div className="devops-detail-section-left">
          <h1>Key Components Defending the Frontlines</h1>
          <div className="lines"></div>

          <br />
          {/* <h3>  Fast on All Planes:</h3> */}
          <p>
        
          Network security forms the initial line of defense, employing tools like firewalls, intrusion detection systems, and encryption to protect data traversing networks. Meanwhile, endpoint security shields individual devices from malicious activities, employing antivirus software, firewalls, and device management tools. Identity and Access Management (IAM) control and govern user access, ensuring authorized personnel have appropriate privileges. Incident response protocols provide a structured approach to addressing and mitigating the impact of security incidents.
          </p>
          <br/>

          <h3>Integration of Emerging Technologies:</h3>
          <p>
          
          The integration of artificial intelligence (AI) and machine learning (ML) has transformed cybersecurity by enhancing threat detection and response capabilities. These technologies analyze vast datasets, identifying patterns and anomalies indicative of potential threats. The Zero Trust Security Model, another innovation, challenges the traditional notion of implicit trust, requiring verification for every entity attempting system access.
          </p>
          <br />
          <h3>Global Significance and Future Outlook:</h3>
          <br />

          <p>
       
          Cybersecurity extends far beyond individual organizations; it has global implications. Nation-states, businesses, and individuals alike are potential targets, with successful cyber attacks capable of inflicting financial losses and compromising national security. Looking ahead, the future of cybersecurity lies in continued innovation, collaboration, and a proactive stance against emerging risks. With the integration of advanced technologies and a heightened focus on education and awareness, the goal is to create a resilient digital ecosystem where security is not just a response but an integral aspect of the evolving digital landscape.
          </p>
          <br/>
          {/* <h3>Integrated Development</h3> */}
          <br/>
          {/* <p>
Azure has an added benefit of having an ingrained development environment i.e. visual Studio, which is a game changer for most companies. This is the main reason for reducing learning associated with a new platform. Any mismatching skill can be altogether avoided and developer teams can be instantaneously deployed on jobs. During Azure Cloud training, students can greatly benefit from its tight integrations and its willingness to adapt to the developers’ needs.</p> */}
        </div>
        <div className="devops-detail-section-right">
          <img src={dev} />
        </div>
      </div>
      {/* <div class="content">
    
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          

          <div class="row justify-content-center">
        
            <div class="col-md-6">
              
              <h3 class="heading mb-4">Let's talk about everything!</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p>

              <p><img src={inquimg} alt="Image" class="img-fluid"/></p>


            </div>
            <div class="col-md-6">
            <br/>
          <br/>
              <form class="mb-5" method="post" id="contactForm" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="name" id="name" placeholder="Your name"/>
                  </div>

                </div>
                <br/>
                <div class="row d-flex flex-row">
                  <div class="col-md-6 form-group">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Email"/>
                  </div>
                  <div class="tech-down col-md-6">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
                </div>
                <br/>

         
                <br/>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <br/>

                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"/>
                  <span class="submitting"></span>
                  </div>
                </div>
                <br/>

              </form>

             
             
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> */}
  <ContactForm/>
    <Footer/>
   </>
  )
}

export default Cybersecurity