import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import blog from '../Assets/Group 11.svg'
import card from '../Assets/Group 11.svg'
import Navbar from '../Components/Navbar'
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer';

function NewsDetail() {
    const { blogId } = useParams();
  const [blog, setBlog] = useState({});
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://kstraining-server.vercel.app/api/news/all');
        const data = await response.json();
        setBlogs(data.data);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://kstraining-server.vercel.app/api/news/single/${blogId}`);
        const data = await response.json();
        setBlog(data.data);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchData();
  }, [blogId]);

  return (
  <>
     <div>
 <Navbar/>
     <div className='workwithus'>
    <h1>Blogs</h1>


    </div>

<br/>
<br/>

<div className='BD'>
      <div className='blogdetail'>
        <img src={card} alt={`Image for ${blog.title}`} />
        <br />
        <h1>{blog.title}</h1>
        <h3>{blog.desc}</h3>
        <p>{blog.content}</p>
        <h4>{blog.publishDate}</h4>
        <h4>Writter Name</h4>
        <button>Read More</button>
      </div>
    </div>

  <br/>


  <div className='blog-cardsa'>
          {blogs.map(blog => (
            <Link className='link' to={`/blog/${blog._id}`} key={blog._id}>
              <div className='blog-carda'>
                <img src={card} alt={`Card for ${blog.title}`} />
                <div className='blogdesc'>
                  <h1>{blog.title}</h1>
                  <p>{blog.content}</p>
                  <h6><i className="bi bi-calendar2-week"></i>{blog.publishDate}</h6>
                  <button>Read More <i className="bi bi-arrow-right-circle"></i></button>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <br/>
        <br/>
  <Footer/>
    </div>

  </>
  )
}

export default NewsDetail