import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import mA from '../Assets/DataScienceHero-iStock-1386436522_0.jpg'
import dev from '../Assets/data-anywhere.svg'
import inquimg from '../Assets/undraw-contact.svg'
import ContactForm from '../Components/ContactForm'

function DataScience() {
  return (
    <>
        <Navbar/>
        <div className="center">
        <img className="aws" src={mA} />
      </div>

      <div className="devops-detail">
        <h1>Data Science</h1>
        <div className="lines"></div>
        <br />
        <p>
        Data Science, at the convergence of statistics, mathematics, and computer science, extracts meaningful insights from data to inform decision-making. The workflow begins with data collection and cleaning, followed by exploratory data analysis and the application of machine learning algorithms. Python and R, along with tools like Jupyter Notebooks and libraries such as Pandas, form the data scientist's toolkit. Applications span industries, with predictive analytics and recommendation systems reshaping traditional approaches. Effective communication using visualization tools is integral. In a dynamic landscape, data science continues to drive innovation, influencing strategic planning and customer experiences, making it a vital force in the digital age.
        </p>
        <br />
        {/* <p>
        Implementing a comprehensive DevOps pipeline for a website involves several key components. Starting with version control, establish a repository using a system like Git and define branching strategies to facilitate collaboration. Enforce branch protection and code review policies to maintain code quality. The continuous integration (CI) phase comes next, where automated builds are configured to trigger upon code commits. Utilize CI tools such as Jenkins or Azure Pipelines to compile code, run tests, and generate artifacts.<br/> Manage these artifacts in a repository like Azure Artifacts or JFrog Artifactory, ensuring proper versioning for traceability. Automated testing is integral to the pipeline, encompassing unit, integration, and end-to-end tests. Unit tests validate individual components, while integration tests verify interactions between them. End-to-end tests ensure the holistic functionality of the website. Throughout this process, consider the automation of testing within the CI/CD pipeline to maintain efficiency. This structured approach to DevOps facilitates continuous integration, enabling faster and more reliable development cycles for 
        </p> */}
      </div>
      <div className="devops-detail-section">
        <div className="devops-detail-section-left">
          <h1> Data Science in Action: Unlocking Insights</h1>
          <div className="lines"></div>

          <br />
          <h3>         
</h3>
          <p>
        
Data Science, a dynamic field encompassing statistics, mathematics, and computer science, navigates the vast realm of data to unveil valuable insights. The process starts with data collection, followed by cleaning and exploratory data analysis. Machine learning algorithms then bring predictive modeling to the forefront. Python and R, paired with tools like Jupyter Notebooks and libraries such as Pandas, empower data scientists in their endeavors. Applications span industries, with predictive analytics, recommendation systems, and more reshaping traditional paradigms. Effective communication of findings, facilitated by visualization tools, is integral to the data science narrative. As a driving force in innovation, data science influences strategic planning and customer experiences, establishing its indispensable role in the digital era.
          </p>
          <br/>

          <h3>Fast and Easy Deployment</h3>
          <p>
          
At the core of Data Science lies a powerful technological arsenal. Programming languages such as Python and R, coupled with tools like Jupyter Notebooks, streamline the data scientist's workflow. Libraries such as Pandas and Scikit-learn elevate data manipulation and machine learning tasks. In addressing the challenges posed by burgeoning data volumes, big data technologies like Apache Spark prove invaluable. This technological toolkit empowers data scientists to navigate complexities, ensuring efficiency in analysis and modeling.
          </p>
          <br />
          <h3>           Data Science Across Industries:
</h3>
          <br />

          <p>
       
The impact of Data Science reverberates across industries, transforming how organizations operate. From finance to healthcare, marketing, and beyond, its applications are diverse. Predictive analytics, recommendation systems, and data-driven decision-making redefine traditional approaches. As organizations embrace the insights derived from data, Data Science becomes a catalyst for innovation, driving strategic advancements and enhancing the adaptability of businesses in an increasingly data-centric landscape.
          </p>
          <br/>
          {/* <h3>Integrated Development</h3> */}
          <br/>
          {/* <p> */}
{/* Azure has an added benefit of having an ingrained development environment i.e. visual Studio, which is a game changer for most companies. This is the main reason for reducing learning associated with a new platform. Any mismatching skill can be altogether avoided and developer teams can be instantaneously deployed on jobs. During Azure Cloud training, students can greatly benefit from its tight integrations and its willingness to adapt to the developers’ needs.</p> */}
        </div>
        <div className="devops-detail-section-right">
          <img src={dev} />
        </div>
      </div>
      {/* <div class="content">
    
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          

          <div class="row justify-content-center">
        
            <div class="col-md-6">
              
              <h3 class="heading mb-4">Let's talk about everything!</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p>

              <p><img src={inquimg} alt="Image" class="img-fluid"/></p>


            </div>
            <div class="col-md-6">
            <br/>
          <br/>
              <form class="mb-5" method="post" id="contactForm" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="name" id="name" placeholder="Your name"/>
                  </div>

                </div>
                <br/>
                <div class="row d-flex flex-row">
                  <div class="col-md-6 form-group">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Email"/>
                  </div>
                  <div class="tech-down col-md-6">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
                </div>
                <br/>

         
                <br/>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <br/>

                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"/>
                  <span class="submitting"></span>
                  </div>
                </div>
                <br/>

              </form>

             
             
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> */}
<ContactForm/>
        <Footer/>
    </>
  )
}

export default DataScience