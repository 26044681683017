import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import burger from "../Assets/Hamburger_icon.svg.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from '../Assets/NEWlogo-removebg-preview.png'

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeMenu);
    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, []);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
    setSearchOpen(false); // Close the search input when opening the dropdown
  };

  const handleSearchToggle = () => {
    setSearchOpen(!isSearchOpen);
    setDropdownOpen(false); // Close the dropdown when opening the search input
  };
  const [modal, setModal] = useState(false);



  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };



  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNo: '',
    technology: ''
  });

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = () => {
    fetch('https://kstraining-server.vercel.app/api/lead/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        // Handle success, e.g., show a success message to the user
        alert('Form submitted successfully!');
        // Reset the form data
        setFormData({
          name: '',
          email: '',
          mobileNo: '',
          technology: ''
        });
        // Close the modal
        toggleModal();
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle error, e.g., show an error message to the user
      });
  };

  return (
    <>
      {/* <nav>
        <div className="nav">
          <div className="logo-ul">
            <div className="logo">
              <p>logo</p>
            </div>
            <div className="firstul">
              <ul>
                <li>Home</li>
                <li>Features</li>
                <li>Resources</li>
                <li>Our Customers</li>
                <li class="dropdown">
                  <button>TECHNOLOGIES</button>

                  <div class="dropdown-content">
                    <a href="#">Technology 1</a>
                    <a href="#">Technology 2</a>
                    <a href="#">Technology 3</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="demo">
            <ul>
              <li>For Candidates</li>
              <li>Log in</li>
              <li>
                <button onClick={() => setModal(true)}>Book a Demo</button>
              </li>
            </ul>
          </div>
          <img className="hum" src={burger} />
        </div>

        <Modal size="lg" isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Contact Us</ModalHeader>
        <ModalBody>
          <div className="contactus">
            <div className="cont-ip">
              <label>Full Name</label>
              <input name="name" placeholder="Enter Your Name" value={formData.name} onChange={handleChange} />
            </div>
            <div className="cont-ip">
              <label>Email</label>
              <input name="email" placeholder="Enter Your Email" value={formData.email} onChange={handleChange} />
            </div>
            <div className="cont-ip">
              <label>Mobile No.</label>
              <input name="mobileNo" placeholder="Enter Your Mobile no." value={formData.mobileNo} onChange={handleChange} />
            </div>
            <div className="cont-ip">
              <label>Technology</label>
              <input name="technology" placeholder="Technology" value={formData.technology} onChange={handleChange} />
            </div>
            <button onClick={handleSubmit}>Submit</button>
          </div>
        </ModalBody>
      </Modal>
      </nav> */}
      <nav className="nav">
     <Link className="navlog" to='/'>
<img className="kslogo" src={logo}/>
</Link>
      <div id="hamburger"
        className={`hamburger ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        &#9776;
      </div>
      <ul className={isMenuOpen ? "open" : ""}>
       
        <li class="dropdown">

        <Link className="liktabs" to='/'>

          <a href="#" className="btn bnav" style={{ textDecoration:'none'}}>
         Home
          </a>
        </Link>

      

        </li>
        <li class="dropdown">
        <Link className="liktabs" to='/'>

          <a href="#" className="btn bnav" style={{ textDecoration:'none'}}>
          Our Courses
          </a>
        </Link>

          <ul class="dropdown-content">
            <div className="ul-containt">
              <div className="ul-containt-wo ul-containt-wo2">
               
              <Link className="caselink" to="/devops">
                <li className="navli">
                DevOps

          

                </li>
              </Link>
               
               <Link className="caselink" to="/java">
                <li className="navli">
               
                Java

               
                </li>
               </Link>
               <Link className="caselink" to="/aws">
                <li className="navli">
               
                AWS

            
                </li>
               </Link>
               <Link className="caselink" to="/azure">
                <li className="navli">
               
                Azure

                </li>
               </Link>

               <Link className="caselink" to="/react">
                <li className="navli">
               
                ReactJS

              
                </li>
               </Link>
               <Link className="caselink" to="/angular">
                <li className="navli">
               
                AngularJS

                </li>
               </Link>
               <Link className="caselink" to="/dotnet">
                <li className="navli">
               
                DotNet

               
                </li>
               </Link>
               <Link className="caselink" to="/mulesoft">
                 <li className="navli">
                
                 MuleSoft
 
                 </li>
                </Link>
                <Link className="caselink" to="/datascience">
                 <li className="navli">
                
                 Data Science
 
                 </li>
                </Link>
       
               
             
              </div>
              <div className="ul-containt-wo ul-containt-wo2">
              <Link className="caselink" to="/cloud">
                 <li className="navli">
                
                 Cloud
 
                 </li>
                </Link>
              <Link className="caselink" to="/cybersecurity">
                 <li className="navli">
                
                 Cyber Security
 
      
                 </li>
                </Link>
                <Link className="caselink" to="/blockchain">
                 <li className="navli">
                
                 Block Chain
 
                 </li>
                </Link>
                <Link className="caselink" to="/powerbi">
                 <li className="navli">
                
                 PowerBI
 
                 </li>
                </Link>
                <Link className="caselink" to="/tableau">
                 <li className="navli">
                
                 Tableau
 
                 </li>
                </Link>
                <Link className="caselink" to="/python">
                 <li className="navli">
                
                 Python
 
          
                 </li>
                </Link>
                <Link className="caselink" to="/hadoop">
                 <li className="navli">
                
                 Hadoop
 
      
                 </li>
                </Link>
                <Link className="caselink" to="/powerapp">
                 <li className="navli">
                
                 Power App
 
                
                 </li>
                </Link>
                <Link className="caselink" to="/salesforce">
                 <li className="navli">
                
                Salesforce
 
                
                 </li>
                </Link>
                
              
               </div>
              
             
            </div>
          </ul>
        </li>
        <li class="dropdown">
        <Link className="liktabs" to='/about'>

          <a href="#" className="btn bnav" style={{ textDecoration:'none'}}>
          About Us
          </a>
          </Link>
        
        </li>
      
        <li class="dropdown">
        <Link className="liktabs" to='/blog'>

          <a href="#" className="btn bnav" style={{ textDecoration:'none'}}>
          Blogs
          </a>
          </Link>
        
        </li>
       
      
       
        <li class="dropdown">
        <Link className="liktabs" to='/workwithus'>

          <a href="#" className="btn bnav" style={{ textDecoration:'none'}}>
           Contact Us
          </a>
        </Link>
       
        </li>
    
     
      
        
      </ul>
    </nav>
    </>
  );
}

export default Navbar;
