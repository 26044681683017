import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import dotnet from '../Assets/dotnet_round.png'
import inquimg from '../Assets/undraw-contact.svg'
import ContactForm from '../Components/ContactForm'

function Dotnet() {
  return (
    <>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <div className="center">
        <img className="devops" src={dotnet} />
      </div>

      <div className="devops-detail">
        <h1>.NET Powering Modern Application Development</h1>
        <div className="lines"></div>
        <br />
        <p>
        .NET, developed by Microsoft, is a versatile and robust framework that has become a cornerstone in modern application development. Spanning a wide array of languages, including C#, VB.NET, and F#, .NET empowers developers to build scalable, secure, and high-performance applications across various platforms.
        </p>
        <br/>
        <p>

       <b> C# Language:</b><br/>
At the heart of .NET lies C#, a powerful and elegant object-oriented language. C# provides developers with a clean syntax, type safety, and modern features, making it well-suited for building a diverse range of applications.
        </p>
        <br />
        {/* <p>
        Implementing a comprehensive DevOps pipeline for a website involves several key components. Starting with version control, establish a repository using a system like Git and define branching strategies to facilitate collaboration. Enforce branch protection and code review policies to maintain code quality. The continuous integration (CI) phase comes next, where automated builds are configured to trigger upon code commits. Utilize CI tools such as Jenkins or Azure Pipelines to compile code, run tests, and generate artifacts.<br/> Manage these artifacts in a repository like Azure Artifacts or JFrog Artifactory, ensuring proper versioning for traceability. Automated testing is integral to the pipeline, encompassing unit, integration, and end-to-end tests. Unit tests validate individual components, while integration tests verify interactions between them. End-to-end tests ensure the holistic functionality of the website. Throughout this process, consider the automation of testing within the CI/CD pipeline to maintain efficiency. This structured approach to DevOps facilitates continuous integration, enabling faster and more reliable development cycles for 
        </p> */}
      </div>
      <div className="devops-detail-section">
        <div className="devops-detail-section-left">
          <h1>Microsoft Azure</h1>
          <div className="lines"></div>

          <br />
          <h3>ASP.NET:</h3>
          <p>
        
          
For web development, ASP.NET offers a comprehensive framework. ASP.NET Core, the latest iteration, is an open-source, cross-platform framework that enables developers to build modern, cloud-based, and connected applications.
          </p>
          <br/>

          <h3>Fast and Easy Deployment</h3>
          <p>
          
Furthermore, you can enhance your business’s agility and speed of operations with MS Azure. With faster deployment services and on premise solutions, companies can greatly benefit from Azure. They can develop their apps faster and can get instant feedback, thus becoming more iterative. This can help companies examine technological outcomes that will accelerate and suit their business objectives.
          </p>
          <br />
          <h3> Entity Framework:</h3>
          <br />

          <p>
       
.NET includes Entity Framework, a powerful Object-Relational Mapping (ORM) framework that simplifies database interactions, allowing developers to work with databases using C# objects.
          </p>
          
        </div>
        <div className="devops-detail-section-right">
          <img src={dotnet} />
        </div>
      </div>

      <div className="devops-detail-section">
   
        <div className="devops-detail-section-right">
          <img src={dotnet} />
        </div>
        <div className="devops-detail-section-left">
       
       
       <h3>          Xamarin:
</h3>
       <br/>
       <p>
.NET extends its capabilities to mobile app development with Xamarin, enabling developers to create cross-platform applications for iOS and Android using a single codebase.
</p>
<br/>
       <h3>                   Azure Integration:

</h3>
       <br/>
       <p>
With seamless integration with Microsoft Azure, .NET provides a scalable and flexible cloud platform for hosting, deploying, and managing applications.
</p>
<br/>
       <h3>            Visual Studio:

</h3>
       <br/>
       <p>
Visual Studio, the integrated development environment (IDE) for .NET, offers a feature-rich environment with tools for debugging, testing, and collaboration, enhancing the developer experience.
</p>
     </div>
      </div>
      {/* <div class="content">
    
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          

          <div class="row justify-content-center">
        
            <div class="col-md-6">
              
              <h3 class="heading mb-4">Let's talk about everything!</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p>

              <p><img src={inquimg} alt="Image" class="img-fluid"/></p>


            </div>
            <div class="col-md-6">
            <br/>
          <br/>
              <form class="mb-5" method="post" id="contactForm" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="name" id="name" placeholder="Your name"/>
                  </div>

                </div>
                <br/>
                <div class="row d-flex flex-row">
                  <div class="col-md-6 form-group">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Email"/>
                  </div>
                  <div class="tech-down col-md-6">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
                </div>
                <br/>

         
                <br/>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <br/>

                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"/>
                  <span class="submitting"></span>
                  </div>
                </div>
                <br/>

              </form>

             
             
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> */}

  <ContactForm/>
        <Footer/>
    </>
  )
}

export default Dotnet