import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import java from "../Assets/java_logo_icon_168609.png";
import dev from "../Assets/java-features.png";
import inquimg from '../Assets/undraw-contact.svg'
import ContactForm from "../Components/ContactForm";

function Java() {
  return (
    <>
      <Navbar />
      <br/>
      <div className="center">
        <img className="devops" src={java} />
      </div>

      <div className="devops-detail">
        <h1>JAVA</h1>
        <div className="lines"></div>
        <br />
        <p>
          Java stands as a pillar in the world of programming languages,
          renowned for its versatility, platform independence, and robust
          object-oriented approach. With a "Write Once, Run Anywhere"
          philosophy, Java allows developers to create applications that can
          seamlessly run on various devices, irrespective of the underlying
          hardware and operating system. This makes Java an ideal choice for
          building cross-platform solutions, from enterprise-level applications
          to mobile and web-based systems. Leveraging the Java Virtual Machine
          (JVM), developers can enjoy the benefits of strong memory management
          and the ability to run Java code across diverse environments. The
          language's rich standard library equips developers with a vast array
          of tools, making it easier to build efficient and feature-rich
          software. Additionally, Java's support for multi-threading facilitates
          the creation of scalable applications, ensuring optimal performance in
          today's demanding computing landscape. Explore the world of Java and
          unlock the potential to build robust, secure, and scalable solutions
          for the digital era.
        </p>
        <br />
        {/* <p>
        Implementing a comprehensive DevOps pipeline for a website involves several key components. Starting with version control, establish a repository using a system like Git and define branching strategies to facilitate collaboration. Enforce branch protection and code review policies to maintain code quality. The continuous integration (CI) phase comes next, where automated builds are configured to trigger upon code commits. Utilize CI tools such as Jenkins or Azure Pipelines to compile code, run tests, and generate artifacts.<br/> Manage these artifacts in a repository like Azure Artifacts or JFrog Artifactory, ensuring proper versioning for traceability. Automated testing is integral to the pipeline, encompassing unit, integration, and end-to-end tests. Unit tests validate individual components, while integration tests verify interactions between them. End-to-end tests ensure the holistic functionality of the website. Throughout this process, consider the automation of testing within the CI/CD pipeline to maintain efficiency. This structured approach to DevOps facilitates continuous integration, enabling faster and more reliable development cycles for 
        </p> */}
      </div>
      <div className="devops-detail-section">
        <div className="devops-detail-section-left">
          <h1>JAVA</h1>
          <div className="lines"></div>

          <br />
          <p>
            Moreover, the Java community actively contributes to open-source
            projects, fostering innovation and collaboration. Integrated
            Development Environments (IDEs) like Eclipse and IntelliJ IDEA
            enhance the development experience, offering powerful features such
            as code completion, debugging tools, and seamless integration with
            version control systems.
          </p>
          <p>
            Whether you're a seasoned developer or just embarking on your coding
            journey, Java's expansive ecosystem provides a wealth of resources
            and tools to propel your projects forward. Dive into the world of
            Java development and join a global community that continues to shape
            the future of software engineering.
          </p>
          <br />
          <p>
            As technology evolves, Java remains at the forefront of modern
            application development, adapting to new paradigms such as
            microservices and cloud-native architectures. Microservices, a
            design approach where applications are composed of small,
            independent services, find a natural fit with Java due to its
            modular and object-oriented nature. Developers can leverage
            frameworks like Spring Boot to rapidly build and deploy
            microservices, enabling agile development and scalability.
          </p>
        </div>
        <div className="devops-detail-section-right">
          <img src={dev} />
        </div>
      </div>
      {/* <div className='inq-from'>
    <h1>For Inquiry</h1>
    <p>Elevate Your Learning Experience in Our Technology Classes!</p>
    <br/>
    <div className='inq-from-ips'>
        <input placeholder='First Name'/>
        <input placeholder='Last Name'/>
    </div>
    <div className='inq-from-ips'>
        <input placeholder='Email'/>
        <input placeholder='Mobile No.'/>
    </div>
    <div className='inq-from-ips'>
        <input placeholder='Address'/>
        <input placeholder='Address'/>
    </div>
 
    <div class="tech-down">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
    
<br/>
<button className='submit-form'>Submit</button>

    </div> */}
    {/* <div class="content">
    
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          

          <div class="row justify-content-center">
        
            <div class="col-md-6">
              
              <h3 class="heading mb-4">Let's talk about everything!</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p>

              <p><img src={inquimg} alt="Image" class="img-fluid"/></p>


            </div>
            <div class="col-md-6">
            <br/>
          <br/>
              <form class="mb-5" method="post" id="contactForm" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="name" id="name" placeholder="Your name"/>
                  </div>

                </div>
                <br/>
                <div class="row d-flex flex-row">
                  <div class="col-md-6 form-group">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Email"/>
                  </div>
                  <div class="tech-down col-md-6">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
                </div>
                <br/>

         
                <br/>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <br/>

                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"/>
                  <span class="submitting"></span>
                  </div>
                </div>
                <br/>

              </form>

             
             
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> */}
  <ContactForm/>
      <Footer />
    </>
  );
}

export default Java;
