import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import mA from '../Assets/AngularJS_logo.svg.png'
import dev from '../Assets/angular-800x508.png'
import inquimg from '../Assets/undraw-contact.svg'
import ContactForm from '../Components/ContactForm'

function AngularJS() {
  return (
   <>
    <Navbar/>
    <div className="center">
    <br/>
    <br/>
        <img className="devops" src={mA} />
    <br/>
    <br/>

      </div>

      <div className="devops-detail">
        <h1>AngularJS: Empowering Dynamic Web Applications</h1>
        <div className="lines"></div>
        <br />
        <p>
        AngularJS, developed and maintained by Google, is a powerful front-end framework that has redefined the way developers build dynamic and interactive web applications. As a full-fledged MVC (Model-View-Controller) framework, AngularJS provides a robust structure for organizing and managing code, enabling the creation of scalable and maintainable applications.
        </p>
        <p>
        One of AngularJS's key features is two-way data binding, allowing changes in the UI to instantly reflect in the underlying data model and vice versa. Directives, another fundamental aspect, extend HTML with custom tags and attributes, enhancing the declarative nature of development. Dependency injection simplifies component architecture, promoting modularization and making components easier to test.
        </p>
        <br />
        <p>
        AngularJS's templating system facilitates the creation of dynamic views, while services and factories handle data and business logic in a modular and reusable manner. Routing in AngularJS enables the creation of single-page applications with seamless navigation.
        </p>
      </div>
      <div className="devops-detail-section">
        <div className="devops-detail-section-left">
          <h1>AngularJS</h1>
          <div className="lines"></div>

          <br />
          {/* <h3>  Fast on All Planes:</h3> */}
          <p>
        
          AngularJS, developed and maintained by Google, has been a stalwart in the realm of front-end web development. As a powerful and feature-rich JavaScript framework, AngularJS enables the creation of dynamic, single-page web applications with ease. Its core strength lies in two-way data binding, allowing seamless synchronization between the model and the view, minimizing boilerplate code and enhancing development efficiency.
          </p>
          <br/>

          {/* <h3>Fast and Easy Deployment</h3> */}
          <p>
          AngularJS embraces a modular and extensible architecture through the use of directives, allowing developers to create reusable components. Dependency injection simplifies testing and promotes the creation of modular and maintainable code. The framework's declarative approach to UI development makes it easy to understand and scale applications.
          </p>
          <br />
          {/* <h3>   Business Agility</h3> */}
          <br />

          <p>
       
          Furthermore, AngularJS provides a comprehensive set of tools and services, including Angular CLI for project scaffolding, RxJS for handling asynchronous operations, and Angular Material for pre-built UI components. With a robust ecosystem and a vibrant community, AngularJS continues to evolve. The more recent versions of the framework, such as Angular (without the "JS"), build upon its foundations to deliver even more sophisticated tools for modern web development. Explore AngularJS to witness the seamless synergy between design and functionality, empowering developers to build dynamic and responsive web applications.
          </p>
          <br/>
          {/* <h3>Integrated Development</h3> */}
          <br/>
         
        </div>
        <div className="devops-detail-section-right">
          <img src={dev} />
        </div>
      </div>
      {/* <div class="content">
    
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          

          <div class="row justify-content-center">
        
            <div class="col-md-6">
              
              <h3 class="heading mb-4">Let's talk about everything!</h3>
              <p>Contact Our Support Team</p>

              <p><img src={inquimg} alt="Image" class="img-fluid"/></p>


            </div>
            <div class="col-md-6">
            <br/>
          <br/>
              <form class="mb-5" method="post" id="contactForm" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="name" id="name" placeholder="Your name"/>
                  </div>

                </div>
                <br/>
                <div class="row d-flex flex-row">
                  <div class="col-md-6 form-group">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Email"/>
                  </div>
                  <div class="tech-down col-md-6">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
                </div>
                <br/>

         
                <br/>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <br/>

                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"/>
                  <span class="submitting"></span>
                  </div>
                </div>
                <br/>

              </form>

             
             
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> */}
  <ContactForm/>
    <Footer/>
   </>
  )
}

export default AngularJS