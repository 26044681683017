import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import mA from '../Assets/python.png'
import dev from '../Assets/Python-logo-notext.svg.png'
import inquimg from '../Assets/undraw-contact.svg'
import ContactForm from '../Components/ContactForm'

function Python() {
  return (
   <>
    <Navbar/>
    <div className="center">
    <br/>
    <br/>
        <img className="devops" src={mA} />
    <br/>
    <br/>

      </div>

      <div className="devops-detail">
        <h1>Python</h1>
        <div className="lines"></div>
        <br />
        <p>
        Python, a high-level, interpreted programming language, has emerged as a cornerstone in various domains, owing to its versatility, readability, and extensive community support. Guido van Rossum created Python with a focus on simplicity and code readability, making it an ideal choice for beginners and seasoned developers alike.
        </p>
        <p>
        Challenges in Python development include its Global Interpreter Lock (GIL) affecting concurrent execution in multithreaded programs. However, ongoing efforts and alternative approaches aim to address this limitation. Future trends in Python development involve the evolution of the language itself, with updates and enhancements focusing on performance, concurrency, and improved syntax.
        </p>
        <br />
        {/* <p>
        Implementing a comprehensive DevOps pipeline for a website involves several key components. Starting with version control, establish a repository using a system like Git and define branching strategies to facilitate collaboration. Enforce branch protection and code review policies to maintain code quality. The continuous integration (CI) phase comes next, where automated builds are configured to trigger upon code commits. Utilize CI tools such as Jenkins or Azure Pipelines to compile code, run tests, and generate artifacts.<br/> Manage these artifacts in a repository like Azure Artifacts or JFrog Artifactory, ensuring proper versioning for traceability. Automated testing is integral to the pipeline, encompassing unit, integration, and end-to-end tests. Unit tests validate individual components, while integration tests verify interactions between them. End-to-end tests ensure the holistic functionality of the website. Throughout this process, consider the automation of testing within the CI/CD pipeline to maintain efficiency. This structured approach to DevOps facilitates continuous integration, enabling faster and more reliable development cycles for 
        </p> */}
      </div>
      <div className="devops-detail-section">
        <div className="devops-detail-section-left">
          <h1>Key Features and Strengths</h1>
          <div className="lines"></div>

          <br />
          {/* <h3>  Fast on All Planes:</h3> */}
          <p>
        
          At the heart of Python's popularity lie its key features. The language's versatility allows it to be applied across diverse applications, ranging from web development and data analysis to artificial intelligence and automation. Python's cross-platform compatibility ensures seamless execution on various operating systems. The richness of its ecosystem, marked by extensive libraries and frameworks, further enhances its appeal. Whether in data science (NumPy, Pandas), web development (Django, Flask), or machine learning (TensorFlow, PyTorch), Python's extensive toolkit meets the demands of a wide spectrum of applications. The strength of Python is not just in its syntax but in its supportive community, providing comprehensive documentation and a plethora of resources.
          </p>
          <br/>

          {/* <h3>Enabling Data-Driven Cultures:</h3> */}
          <p>
          
          Python's influence extends across various domains, playing a pivotal role in web development, data science, machine learning, automation, and artificial intelligence. In web development, frameworks like Django and Flask enable rapid and scalable application development. The language's prowess in data science is exemplified by libraries such as Pandas and NumPy, facilitating efficient data analysis and manipulation. Python has become the language of choice for machine learning, with TensorFlow and PyTorch leading the way in developing and deploying complex AI algorithms. Additionally, its scripting capabilities make it a go-to language for automation, simplifying repetitive tasks and system administration.
          </p>
          <br />
          {/* <h3>Challenges and Ongoing Advancements:

</h3> */}
          <br />

          {/* <p>
       
          While the implementation of Power BI offers significant advantages, challenges may include ensuring data quality, managing security, and addressing the learning curve for new users. The future trajectory of Power BI involves embracing advancements in artificial intelligence and machine learning integration, promising even more sophisticated analytical capabilities.
          </p> */}
          <br/>
          {/* <h3>Integrated Development</h3> */}
          <br/>
          {/* <p>
Azure has an added benefit of having an ingrained development environment i.e. visual Studio, which is a game changer for most companies. This is the main reason for reducing learning associated with a new platform. Any mismatching skill can be altogether avoided and developer teams can be instantaneously deployed on jobs. During Azure Cloud training, students can greatly benefit from its tight integrations and its willingness to adapt to the developers’ needs.</p> */}
        </div>
        <div className="devops-detail-section-right">
          <img src={dev} />
        </div>
      </div>
      {/* <div class="content">
    
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          

          <div class="row justify-content-center">
        
            <div class="col-md-6">
              
              <h3 class="heading mb-4">Let's talk about everything!</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p>

              <p><img src={inquimg} alt="Image" class="img-fluid"/></p>


            </div>
            <div class="col-md-6">
            <br/>
          <br/>
              <form class="mb-5" method="post" id="contactForm" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="name" id="name" placeholder="Your name"/>
                  </div>

                </div>
                <br/>
                <div class="row d-flex flex-row">
                  <div class="col-md-6 form-group">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Email"/>
                  </div>
                  <div class="tech-down col-md-6">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
                </div>
                <br/>

         
                <br/>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <br/>

                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"/>
                  <span class="submitting"></span>
                  </div>
                </div>
                <br/>

              </form>

             
             
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> */}
  <ContactForm/>
    <Footer/>
   </>
  )
}

export default Python