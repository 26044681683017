
import card from '../Assets/Group 11.svg'
import Footer from '../Components/Footer';
import Navbar from '../Components/Navbar'
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Blog() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://kstraining-server.vercel.app/api/blog/all');
        const data = await response.json();
        setBlogs(data.data);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <>

   <Navbar/>
        <div className='workwithus'>
          <h1>Blogs</h1>
        </div>
        <br />
        <br />
        <div className='blog-cardsa'>
          {blogs.map(blog => (
            <Link className='link' to={`/blog/${blog._id}`} key={blog._id}>
              <div className='blog-carda'>
                <img src={card} alt={`Card for ${blog.title}`} />
                <div className='blogdesc'>
                  <h1>{blog.title}</h1>
                  <p>{blog.content}</p>
                  <h6><i className="bi bi-calendar2-week"></i>{blog.publishDate}</h6>
                  <button>Read More <i className="bi bi-arrow-right-circle"></i></button>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <br />
        <br />
      <Footer/>

   
        
    </>
  )
}

export default Blog