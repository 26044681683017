import React from 'react'
import dev from '../Assets/aws_sysops.jpeg'
import Navbar from "../Components/Navbar";
import aws from '../Assets/Group-169-3.webp'
import Footer from '../Components/Footer';
import inquimg from '../Assets/undraw-contact.svg'
import ContactForm from '../Components/ContactForm';

function AWS() {
  return (
    <>
    <Navbar />

    
    <div className="center">
      <img className="aws" src={aws} />
    </div>

    <div className="devops-detail">
      <h1>Amazon Web Services</h1>
      <div className="lines"></div>
      <br />
      <p>
      Amazon Web Services (AWS) stands as a pioneering force in cloud computing, offering a vast array of services that empower businesses to innovate and scale their operations. From scalable compute power with Amazon EC2 to flexible storage solutions like Amazon S3, AWS provides a comprehensive suite of cloud services. AWS Lambda introduces serverless computing, allowing developers to focus solely on code without managing infrastructure. Amazon RDS simplifies database management, while services like AWS Elastic Beanstalk streamline application deployment. With a global network of data centers, robust security features, and a pay-as-you-go pricing model, AWS provides the foundation for organizations to build, deploy, and scale applications with unparalleled flexibility and efficiency. Explore the limitless possibilities of cloud computing with AWS, where innovation knows no bounds.
      </p>
      <br />
      {/* <p>
      Implementing a comprehensive DevOps pipeline for a website involves several key components. Starting with version control, establish a repository using a system like Git and define branching strategies to facilitate collaboration. Enforce branch protection and code review policies to maintain code quality. The continuous integration (CI) phase comes next, where automated builds are configured to trigger upon code commits. Utilize CI tools such as Jenkins or Azure Pipelines to compile code, run tests, and generate artifacts.<br/> Manage these artifacts in a repository like Azure Artifacts or JFrog Artifactory, ensuring proper versioning for traceability. Automated testing is integral to the pipeline, encompassing unit, integration, and end-to-end tests. Unit tests validate individual components, while integration tests verify interactions between them. End-to-end tests ensure the holistic functionality of the website. Throughout this process, consider the automation of testing within the CI/CD pipeline to maintain efficiency. This structured approach to DevOps facilitates continuous integration, enabling faster and more reliable development cycles for 
      </p> */}
    </div>
    <div className="devops-detail-section">
      <div className="devops-detail-section-left">
        <h1>AWS: Driving Cloud Excellence</h1>
        <div className="lines"></div>

        <br />
        <p>
        Amazon Web Services (AWS) remains an unparalleled force in cloud computing, empowering businesses of all sizes to transcend traditional IT boundaries. AWS offers a comprehensive suite of services spanning computing power, storage, databases, machine learning, and more. Amazon EC2 allows users to provision virtual servers in the cloud, while Amazon S3 provides secure and scalable object storage. AWS Lambda enables serverless computing, facilitating efficient, event-driven applications without the need for server management. With Amazon RDS, managing relational databases becomes seamless, and AWS Elastic Beanstalk simplifies the deployment of scalable applications.
        </p>
        <p>
        Security is paramount in AWS, with robust features such as Identity and Access Management (IAM), Virtual Private Cloud (VPC), and AWS Key Management Service (KMS). The AWS Marketplace further extends capabilities, offering a plethora of third-party solutions and services. From startups to enterprises, AWS provides a global infrastructure, enabling organizations to innovate, scale, and transform their operations. In a dynamic digital landscape, AWS stands as a beacon of reliability, flexibility, and efficiency, driving cloud excellence and revolutionizing the way we approach technology. Explore AWS to unlock the full potential of cloud computing and propel your organization towards unprecedented success.
        </p>
        <br />
      
      </div>
      <div className="devops-detail-section-right">
        <img src={dev} />
      </div>
    </div>
    {/* <div class="content">
    
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          

          <div class="row justify-content-center">
        
            <div class="col-md-6">
              
              <h3 class="heading mb-4">Let's talk about everything!</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p>

              <p><img src={inquimg} alt="Image" class="img-fluid"/></p>


            </div>
            <div class="col-md-6">
            <br/>
          <br/>
              <form class="mb-5" method="post" id="contactForm" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="name" id="name" placeholder="Your name"/>
                  </div>

                </div>
                <br/>
                <div class="row d-flex flex-row">
                  <div class="col-md-6 form-group">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Email"/>
                  </div>
                  <div class="tech-down col-md-6">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
                </div>
                <br/>

         
                <br/>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <br/>

                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"/>
                  <span class="submitting"></span>
                  </div>
                </div>
                <br/>

              </form>

             
             
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> */}
  <ContactForm/>
    <Footer />
  </>
  )
}

export default AWS