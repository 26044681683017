import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import mA from '../Assets/react.jpeg'
import dev from '../Assets/download.png'
import inquimg from '../Assets/undraw-contact.svg'
import ContactForm from '../Components/ContactForm'


function ReactJs() {
  return (
   <>
    <Navbar/>
 

    <div className="center">
        <img className="aws" src={mA} />
      </div>

      <div className="devops-detail">
        <h1>ReactJS: Transforming Front-End Development</h1>
        <div className="lines"></div>
        <br />
        <p>
        ReactJS, developed and maintained by Facebook, has revolutionized the landscape of front-end web development. Renowned for its declarative and component-based approach, React simplifies the creation of dynamic and interactive user interfaces. The Virtual DOM, a key feature, ensures efficient updates by selectively rendering only the changed parts of a page, resulting in enhanced performance.
        </p>
        <p>
        One of React's strengths lies in its reusability and modularity. Components, the building blocks of React applications, encapsulate functionality, making it easier to manage and maintain code. React's unidirectional data flow ensures predictability and simplifies debugging. The ecosystem surrounding React is vast, with tools like Redux for state management and React Router for navigation, enhancing its capabilities.
        </p>
        <br />
        <p>
        Moreover, React Native extends React's paradigm to mobile app development, enabling developers to build cross-platform applications using familiar React concepts. With a vibrant community, continuous updates, and a strong focus on user experience, ReactJS remains a go-to library for developers aiming to create modern, responsive, and scalable user interfaces. Dive into the world of ReactJS and witness the transformation it brings to front-end development.
        </p>
      </div>
      <div className="devops-detail-section">
        <div className="devops-detail-section-left">
          <h1>ReactJS</h1>
          <div className="lines"></div>

          <br />
          {/* <h3>  Fast on All Planes:</h3> */}
          <p>
        
          ReactJS continues to redefine the art of web development, providing developers with a powerful library for crafting intuitive and high-performance user interfaces. Its component-based architecture promotes code reusability, simplifying the creation of complex applications. JSX, a syntax extension for JavaScript used with React, allows developers to write HTML-like code within JavaScript, enhancing readability and facilitating the integration of logic with the user interface.
          </p>
          <br/>

          {/* <h3>Fast and Easy Deployment</h3> */}
          <p>
          React's efficient handling of the Virtual DOM ensures optimal rendering, resulting in faster updates and a smoother user experience. The unidirectional data flow, coupled with a one-way binding approach, enhances predictability and simplifies the tracking of state changes. React's ecosystem includes tools like Create React App for quick project setup and Next.js for building server-side rendered applications, offering flexibility to developers at every stage of the development process.
          </p>
          <br />
          {/* <h3>   Business Agility</h3> */}
          <br />

          <p>
       
          As a testament to its versatility, React Native extends React's capabilities to mobile app development, allowing for the creation of cross-platform applications. With a focus on community-driven innovation and a commitment to enhancing developer experience, ReactJS remains at the forefront of modern front-end development, empowering developers to build elegant and responsive user interfaces. Explore ReactJS and discover a world where UI development becomes a seamless and enjoyable experience.
          </p>
          <br/>
          {/* <h3>Integrated Development</h3> */}
          <br/>
         
        </div>
        <div className="devops-detail-section-right">
          <img src={dev} />
        </div>
      </div>
      {/* <div class="content">
    
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          

          <div class="row justify-content-center">
        
            <div class="col-md-6">
              
              <h3 class="heading mb-4">Let's talk about everything!</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas debitis, fugit natus?</p>

              <p><img src={inquimg} alt="Image" class="img-fluid"/></p>


            </div>
            <div class="col-md-6">
            <br/>
          <br/>
              <form class="mb-5" method="post" id="contactForm" name="contactForm">
                <div class="row">
                  <div class="col-md-12 form-group">
                    <input type="text" class="form-control" name="name" id="name" placeholder="Your name"/>
                  </div>

                </div>
                <br/>
                <div class="row d-flex flex-row">
                  <div class="col-md-6 form-group">
                    <input type="text" class="form-control" name="email" id="email" placeholder="Email"/>
                  </div>
                  <div class="tech-down col-md-6">
    <select class="dropdown-selector">
        <option value="" disabled selected>Select Technology</option>
        <option value="option1">DevOps</option>
        <option value="option2">Java</option>
        <option value="option3">AWS</option>
        <option value="option1">ReactJS</option>
        <option value="option2">AngularJS</option>
        <option value="option3">DotNet</option>
        <option value="option1">MuleSoft</option>
        <option value="option2"> Data Science</option>
        <option value="option3">Cloud</option>
        <option value="option1">Block Chain</option>
        <option value="option2">PowerBI</option>
        <option value="option3">Tableau</option>
        <option value="option2"> Python</option>
        <option value="option3">Hadoop</option>
        <option value="option1">Power App
 </option>
        
    </select>

      
    </div>
                </div>
                <br/>

         
                <br/>

                <div class="row">
                  <div class="col-md-12 form-group">
                    <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                  </div>
                </div>  
                <br/>

                <div class="row">
                  <div class="col-12">
                    <input type="submit" value="Send Message" class="btn btn-primary rounded-0 py-2 px-4"/>
                  <span class="submitting"></span>
                  </div>
                </div>
                <br/>

              </form>

             
             
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> */}
  <ContactForm/>

    <Footer/>
   </>
  )
}

export default ReactJs